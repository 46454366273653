import * as types from "./types";

const intitialState = {
  loggedIn: false,
  dataLogin: null,
  error: "",
};

const loginReducer = (state = intitialState, action) => {
  switch (action.type) {
    case types.LOGIN:
      return {
        ...state,
        loggedIn: true,
        error: "",
        dataLogin: action.data,
      };

    case types.LOGIN_ERROR:
      return {
        ...state,
        loggedIn: false,
        dataLogin: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export default loginReducer;
