const timer = function(callback, delay) {
    let timerId, 
        start, 
        remaining = delay;

    this.pause = function() {
        window.clearTimeout(timerId);
        remaining -= Date.now() - start;
    };

    this.resume = function() {
        start = Date.now();
        window.clearTimeout(timerId);
        timerId = window.setTimeout(callback, remaining);
    };

    this.stop = function() {
        window.clearTimeout(timerId);
    }

    this.resume();
}

export default timer