import styled from 'styled-components'

const Bound = styled.div`
    position: relative;
    width: 100%;
    height: 60px;
    background-color: #fff;
    .block-status-color{
        position: absolute;
        width: 200px;
        height: 100%;
    }
    .success{
        background-color: #EFF9FF
    }
    .fail{
        background-color: #FFEEEE
    }
    .icon-arrow{
        position: absolute;
        z-index: 1;
        width: 18px;
        height: 18px;
        /* background-color: pink; */
        top: 50%;
        left: 190px;
        transform: translate(0, -50%);
    }
`

const WrapItem = styled.div`
    position: absolute;
    z-index: 1;
    display: grid;
    grid-template-columns: 
        /* 60px */
        85px 
        minmax( 120px, 145px)
        minmax( 80px, 170px) 
        minmax( 100px, 1fr) 
        minmax( 110px, 130px) 
        minmax( 110px, 130px) 
        minmax( 60px, 80px);
    padding: 0 20px;
    grid-gap: 5px; 
    width: calc(100% - 40px);
    height: 100%;
    box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: #313131;
    align-items: center;
    &:hover {
        cursor: pointer;
        width: calc(100% - 42px);
        height: 58px;
        border: 1px solid #33ADFF;
    }
    
    div{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .wrap-avatar {
        height: 36px;
        border-radius: 50%;
        .avatar{
            width: 36px;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
            
            &:hover {
                cursor: pointer;
            }
        }
        &.face{
            padding-left: 15px
        }

        
    }

    .matched{
        color: #33ADFF !important;
    }
    .not-match{
        color: #F45C5C !important;
    }
`;

export {
    Bound,
    WrapItem
}