import styled, { keyframes } from 'styled-components'

const rotateInfinity = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
`

const WrapLoading = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    .wrap-overlay {
        max-width: 1280px;

        .icon {
            animation: ${rotateInfinity} 2s linear infinite;
        }

        .wrap-notify {
            margin-top: 80px;
            min-width: 424px;
            width: 424px;
            margin: 80px auto 0;
            border: 2px solid #33ADFF;
            box-sizing: border-box;
            border-radius: 4px;
            padding: 13px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #EFF9FF;
    
            span {
                margin-left: 4px;
                font-family: "Open Sans";
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 18px;
                letter-spacing: -0.01em;
                color: #313131;
            }
        }
    }
`;

export {
    WrapLoading
}