import React from 'react'

// constant
import * as path from '../constant/path'

//images
import IcMenuUser from '../assets/images/IcMenuUser'
import IcMenuUserActive from '../assets/images/IcMenuUserActive'
import IcFindMany from '../assets/images/IcFindMany'
import IcFindManyActive from '../assets/images/IcFindManyActive'
import IcFindOne from '../assets/images/IcFindOne'
import IcFindOneActive from '../assets/images/IcFindOneActive'
import IcComparison from '../assets/images/IcComparison'
import IcComparisonActive from '../assets/images/IcComparisonActive'

const dataMenu = [
    {
        path: `/${path.MAIN}/${path.ENROLL}`,
        Icon: IcMenuUser,
        IconActive: IcMenuUserActive,
        name: 'Enroll'
    },
    {
        path: `/${path.MAIN}/${path.MATCHING_1_N}`,
        Icon: IcFindMany,
        IconActive: IcFindManyActive,
        name: 'Matching 1:N'
    },
    {
        path: `/${path.MAIN}/${path.MATCHING_1_1}`,
        Icon: IcFindOne,
        IconActive: IcFindOneActive,
        name: 'Matching 1:1'
    },
    {
        path: `/${path.MAIN}/${path.FACE_COMPARISON}`,
        Icon: IcComparison,
        IconActive: IcComparisonActive,
        name: 'Face Comparison'
    },
]

export default dataMenu