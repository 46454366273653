import React, { useState, useEffect } from 'react'

// styles
import { Bound } from './index.styles'

//host
import { apiDownLoadAvatar } from '../../../../../../constant/host'

//help
import { convertDateTime } from '../../../../../../helper/tools'

//image
import IcFacePrintDefault from '../../../../../../assets/images/IcFacePrintDefault'

// component
import Portal from '../../../../../Control/Portal'
import ShowZoomFace from '../../Control/ShowZoomFace'

export default function HistoryItem(props) {

    const { className, item } = props

    const [isErrImg, setIsErrImg] = useState(false)
    const [isZoomFace, setIsZoomFace] = useState(false);

    useEffect(() => {
        setIsErrImg(false)
    }, [item?.id])

    const lstFaces = [
        {
            imgUrl: apiDownLoadAvatar(item.cif),
            imgTitle: "Face Picture 1"
        }
    ]

    return (
        <Bound className={className}>
            {/* <div>{item.index}</div> */}
            <div className="wrap-avatar">
                {
                    isErrImg ?
                        <IcFacePrintDefault className="avatar" />
                        :
                        <img
                            src={item?.cif ? apiDownLoadAvatar(item?.cif) : ""}
                            className="avatar"
                            alt="avatar"
                            onError={() => setIsErrImg(true)}
                            onClick={() => setIsZoomFace(true)}
                        />
                }
            </div>
            <div>{item?.cif || ""}</div>
            <div>{item?.processingTime ? `${Math.round(item?.processingTime * 100)/100}ms` : ""}</div>
            <div>{item?.requestTime ? convertDateTime(item?.requestTime, "DD/MM/YYYY") : ""}</div>
            <div>{item?.requestTime ? convertDateTime(item?.requestTime, "HH:mm") : ""}</div>
            {
                isZoomFace &&
                <Portal>
                    <ShowZoomFace 
                        lstFaces={lstFaces}
                        onClose={() => setIsZoomFace(false)}
                    />
                </Portal>
            }
        </Bound>
    )
}
