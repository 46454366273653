import React from 'react'
import { useSelector } from 'react-redux'

// component
import Content from './Content'
import Header from './Header'
import SideMenu from './SideMenu'
import Notify from './Notify'
import Portal from '../Control/Portal'
import Loading from '../Control/Loading'

// styles
import { WrapMain } from './index.styles'

const Main = () => {
    const { isLoading } = useSelector(state => ({
        isLoading: state.loadingReducer.isLoading
    }))

    return (
        <WrapMain>
            <Header className="header" />
            <SideMenu />
            <Content />
            <Notify />
            { isLoading && <Loading /> }
        </WrapMain>
    )
}

export default Main
