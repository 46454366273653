import React from 'react'

//style
import { ButtonWrapper } from './index.styles'


const Button = ({ text, isDisable, className, iconBtn, onClick, ...props}) => {
    return (
        <ButtonWrapper className={className} onClick={onClick} isDisable={isDisable}>
            <div className={`wrap-button${isDisable ? " disable" : ""}`}>
                {
                    iconBtn && iconBtn
                }
                <button {...props}>{text}</button>
            </div>
        </ButtonWrapper>
    )
}

export default Button
