import * as types from './types'

const intitialState = {
    list: [],
}

const verifyReducer = (state = intitialState, action) => {
    switch (action.type) {
        case types.VERIFY_USER: {
            return {
                ...state,
                ...action.payload
              }
        }             
        case types.VERIFY_USER_ERR: {
            return intitialState
        }
        case types.RESET_VERIFY_USER: {
            return intitialState
        }    
        default:
            return state;
    }

}

export default verifyReducer