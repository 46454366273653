import Axios from "axios";
//host
import { host } from "../../../constant/host"
//help
import checkIsSuccessAction from "../../../helper/checkIsSuccessAction";
import mapErrors from "../../../helper/mapErrors";
//type
import * as types from './types'

const apiVerifyFilterHistory = `${host}/facepay/verify/filterHistory`

export const verifyFilterHistory = ({ filterFrom, filterSkip, filterSize }, callbackSuccess) => async dispatch => {

    const condFilters = {
        filterFrom,
        filterSkip,
        filterSize,
    }

    const dataRequest = JSON.stringify({
        filterFrom,
        filterSkip,
        filterSize,
    })

    try {
        const res = await Axios.post(apiVerifyFilterHistory, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.VERIFY_FILTER_HISTORY,
                payload: data,
                condFilters
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.VERIFY_FILTER_HISTORY_ERR,
                payload: data
            })
        }

    } catch (error) {
        dispatch(mapErrors(error, types.VERIFY_FILTER_HISTORY_ERR))    
    }
}

export const resetVerifyFilterHistory = (callbackSuccess) => async dispatch => {
    dispatch({
        type: types.RESET_VERIFY_FILTER_HISTORY
    })
    callbackSuccess && callbackSuccess()
}