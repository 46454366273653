import Axios from "axios";
//host
import { host } from "../../constant/host"
//help
import checkIsSuccessAction from "../../helper/checkIsSuccessAction";
import mapErrors from "../../helper/mapErrors";
//type
import * as types from './types'

// action
import { disableLoading, enableLoading } from "../loading/action";

const apiGetUser = `${host}/facepay/getUser`

let isCalling = false
export const getUser = ({ cif }, callbackSuccess, callbackError) => async dispatch => {
    if(isCalling) return;
    isCalling = true
    dispatch(enableLoading())
    const dataRequest = JSON.stringify({
        cif,
    })

    try {
        const res = await Axios.post(apiGetUser, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_USER,
                payload: data
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_USER_ERR,
                payload: data
            })
            callbackError && callbackError()
        }

    } catch (error) {
        dispatch(mapErrors(error, types.GET_USER_ERR))    
    }
    dispatch(disableLoading())
    isCalling = false
}

export const resetGetUser = () => async dispatch => {
    dispatch({
        type: types.RESET_GET_USER
    })
}

