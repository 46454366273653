import React, { useState } from 'react'

//style
import { InputWrapper } from './index.styles'

const TEXT = "TEXT"
const TEXT_ACTIVE = "TEXT_ACTIVE"
const TEXT_ERROR = "TEXT_ERROR"

const Input = React.forwardRef(({ iconInput, inputType, error, handleValidationInput, className = "", ...props }, ref) => {
    const [typeInput,setTypeInput] = useState(inputType)
    const [errorInput,setErrorInput] = useState("")
    const [errorBorder,setErrorBorder] = useState(false) 

    const _handleFocusInput = () =>{
        setErrorInput("")
        setErrorBorder(false)
        switch(typeInput){
            case TEXT:
                setTypeInput(TEXT_ACTIVE)
                break;
            case TEXT_ERROR:
                setTypeInput(TEXT_ACTIVE)
                break;
            default: return;
        }
    }

    const _handleBlurInput = () =>{
            setTypeInput(TEXT)   
            const callback = (errorCallback) =>{
                setErrorInput(errorCallback)
                setErrorBorder(true)
                setTypeInput(TEXT_ERROR)
            }
            handleValidationInput && handleValidationInput(props.value,callback)
        }

    //handle typeInput show icon
    const handleTypeInput = (type) =>{
        if(!iconInput) return
        switch(type){
            case TEXT:
                return <iconInput.icon className="icon-left" />
            case TEXT_ACTIVE: 
                return <iconInput.iconActive className="icon-left"/>
            case TEXT_ERROR: 
                return <iconInput.iconError className="icon-left"/>
            default: return;
        }
    }
    const iconTypeInput = handleTypeInput(typeInput)
    
    return (
        <InputWrapper className={className}>
            <div className="wrap-input">
                {   iconInput &&
                    iconTypeInput 
                }
                <input ref={ref}
                    {...props} 
                    onFocus={_handleFocusInput}
                    onBlur={_handleBlurInput}
                    className={`${errorBorder ? "error-input" : ""}`}
                />
            </div>
            {
                errorInput &&
                <div className="error">{errorInput}</div>
            }
        </InputWrapper>
    )
})

export default Input
