import Axios from "axios";
//host
import { host } from "../../constant/host"
//help
import checkIsSuccessAction from "../../helper/checkIsSuccessAction";
import mapErrors from "../../helper/mapErrors";
//type
import * as types from './types'

// action
import { disableLoading, enableLoading } from "../loading/action";

const apiCompareUser = `${host}/facepay/compareTwoFaces`

let isCalling = false
export const getCompareUser = ({ photoA, photoB }, callbackSuccess, callbackError, callbackFinal) => async dispatch => {
    if(isCalling) return;
    isCalling = true
    dispatch(enableLoading())
    // photoA, photoB is base64
    const dataRequest = JSON.stringify({ photoA, photoB });

    try {
        const res = await Axios.post(apiCompareUser, dataRequest);
        const { data } = res
        callbackSuccess && callbackSuccess()
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.COMPARE_USER,
                payload: data
            })
        }
        else {
            dispatch({
                type: types.COMPARE_USER_ERR,
                payload: data
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.COMPARE_USER_ERR))
    }  
    dispatch(disableLoading())
    callbackFinal && callbackFinal()
    isCalling = false
}

export const resetCompareUser = () => async dispatch => {
    dispatch({
        type: types.RESET_COMPARE_USER
    })
}