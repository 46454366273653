import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
//styles
import { WrapHeaderContent } from './index.styles'

//help
import numberFormatToThousand from '../../../../helper/numberFormatToThousand';
import { host } from '../../../../constant/host'
//action
import { getCountUser } from '../../../../redux/enrol/action';
import { apiGetLogoBank } from '../../../../constant/host';


const HeaderContent = () => {

    const dispatch = useDispatch()

    const { total, loginReducer } = useSelector(state => ({
        total: state.enrolReducer.countUser.total,
        loginReducer: state.loginReducer
    }))

    const [stringTransaction, setStringTransaction] = useState("")
    const [count, setCount] = useState("0")
    const [urlLogo, setUrlLogo] = useState("")
    const [supplierShortName, setSupplierShortName] = useState("")

    useEffect(() => {
        if (!total) return
        setCount(total)
    }, [total])

    useEffect(() => {
        if(!loginReducer?.dataLogin) return
        if(loginReducer.dataLogin?.supplierLogoPath && loginReducer.dataLogin?.supplierLogoPath.length > 0){
            setUrlLogo(`${host}${loginReducer.dataLogin?.supplierLogoPath}`)
        }
        if(loginReducer.dataLogin?.supplierShortName && loginReducer.dataLogin?.supplierShortName.length > 0){
            setSupplierShortName(loginReducer.dataLogin?.supplierShortName)
        }
        return () => {
            
        }
    }, [JSON.stringify(loginReducer)])

    useEffect(() => {
        dispatch(getCountUser())
    }, [])

    useEffect(() => {
        if (count <= 1) {
            setStringTransaction("User")
        } else if (count > 1) {
            setStringTransaction("Users")
        }
    }, [count])

    return (
        <WrapHeaderContent>
            <div className="wrap-header-content">
                <div className="bank_info">
                    {
                        urlLogo &&
                        <img
                            className="logo"
                            // src={apiGetLogoBank()}
                            src={urlLogo}
                            alt="logo"
                        />
                    }
                    <p className="name_bank">{supplierShortName?supplierShortName:'-'}</p>
                </div>
                <p className="transaction">{numberFormatToThousand(count)} {stringTransaction}</p>
            </div>
        </WrapHeaderContent>
    )
}

export default HeaderContent
