import React, { useEffect } from 'react'

// component
import IcCloseOutlineRed from '../../../../assets/images/IcCloseOutlineRed'
import IcCheckOutlineBlue from '../../../../assets/images/IcCheckOutlineBlue'

// styles
import { TIME_DELAY_CLOSE, toastTypes, WrapToast } from './index.styles'

const Toast = ({ type, onClose, children }) => {

    useEffect(() => {
        const timer = setTimeout(() => {
            onClose()
        }, TIME_DELAY_CLOSE)
        return () => {
            clearTimeout(timer)
        }
    }, []);

    const iconToast = type === toastTypes.SUCCESS ? <IcCheckOutlineBlue /> : <IcCloseOutlineRed />

    return (
        <WrapToast type={type}>
            {iconToast}
            <span>{children}</span>
        </WrapToast>
    )
}

export default Toast
