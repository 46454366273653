import React from 'react'
import IcLoading from '../../../assets/images/IcLoading'

// styles
import { WrapLoading } from './index.styles'

const Loading = () => {
    return (
        <WrapLoading>
            <div className="wrap-overlay">
                <div className="wrap-notify">
                    <IcLoading className="icon" />
                    <span>Đang xử lý...</span>
                </div>
            </div>
        </WrapLoading>
    )
}

export default Loading
