const LOGIN = 'login'
const MAIN = 'main'
const ENROLL = 'enroll'
const MATCHING_1_N = 'matching-1-n'
const MATCHING_1_1 = 'matching-1-1'
const FACE_COMPARISON = 'face-comparison'
const PARTNER = 'partner'

export {
    LOGIN,
    MAIN,
    ENROLL,
    MATCHING_1_N,
    MATCHING_1_1,
    FACE_COMPARISON,
    PARTNER
}