import React from 'react'

const IcFindOne18x18 = (props) => {
    return (
        <svg {...props} width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.24999 5.65165H16.5533" stroke="#313131" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.3258 2.42407L16.5533 5.65155L13.3258 8.87903" stroke="#313131" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.5533 12.4017H2.24999" stroke="#313131" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.47748 9.17407L2.25 12.4016L5.47748 15.629" stroke="#313131" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcFindOne18x18
