import React, { useState, useRef, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

// styles
import { WrapEnroll, extendStylesEnrollForm, TableHeaderEnroll, TableContentEnroll } from './index.styles'
// component
import FormSubmitControl from '../Control/FormSubmitControl'
import PageHeader from '../Control/PageHeader'
import EmptyDataControl from '../Control/EmptyDataControl'
import HistoryItem from './HistoryItem'
import Input from '../../../../Control/Input'
import DragAndDropImage from '../../../../Control/DragAndDropImage'
import Button from '../../../../Control/Button'

//image
import IcInputUser from '../../../../../assets/images/IcInputUser'
import IcInputUserActive from '../../../../../assets/images/IcInputUserActive'
import IcInputUserError from '../../../../../assets/images/IcInputUserError'
import IcBtnRegister from '../../../../../assets/images/IcBtnRegister'
import IcGenerate from '../../../../../assets/images/IcGenerate'

// action
import { enrollNewUser } from '../../../../../redux/enrol/action'
import { getFilterUser, resetFilterUser } from '../../../../../redux/enrol/filterUser/action'
import { getCountUser } from '../../../../../redux/enrol/action';

// helper
import fileToBase64 from '../../../../../helper/fileToBase64'
import * as tools from '../../../../../helper/tools'

//data
import { errorInput } from '../../../../../data/errorInput'
import { enableLoading } from '../../../../../redux/loading/action'

const FILTER_SIZE = -1

const dataHeader = [
    // "#",
    "Faceprint",
    "Customer Number",
    "Processing Time",
    "Requested Date",
    "Time"
]

const iconUser = {
    icon: IcInputUser,
    iconActive: IcInputUserActive,
    iconError: IcInputUserError,
}

const Enroll = () => {
    const dispatch = useDispatch()
    const listRef = useRef(null)
    const cifRef = useRef(null)

    const [listData, setListData] = useState([])
    const [valueCIF, setValueCIF] = useState("")
    // const [checkForm, seCheckForm] = useState(true)
    const [isDisabledBtnRegister, setIsDisabledBtnRegister] = useState(true);
    const [faceImg, setFaceImg] = useState(null);

    const { filterUserReducer } = useSelector(state => ({
        filterUserReducer: state.filterUserReducer
    }))

    useEffect(() => {
        dispatch(getFilterUser({ filterFrom: '', filterSkip: 0, filterSize: FILTER_SIZE }))
        dispatch(getCountUser())
        return () => {
            
        }
    }, [])

    useEffect(() => {
        const isEnable = valueCIF.length === 13 && !!faceImg
        setIsDisabledBtnRegister(!isEnable)
    }, [faceImg, valueCIF]);

    useEffect(() => {
        if(!filterUserReducer?.userList) return
        let list = filterUserReducer.userList
        
        for (let i = 0; i < filterUserReducer.userList.length; i++) {
            let item = filterUserReducer.userList[i];
            let index = filterUserReducer.userList.length - i
            item.index = tools.formatStringID(index, 6);
        }
        if(list) setListData(list)
        return () => {
            
        }
    }, [JSON.stringify(filterUserReducer)])


    const _handleValidateInput = (value,callback) =>{
        if (!value.trim() || 0 === value.length) {
            callback(errorInput.errorEmpty)
            return
        }
        if (value.indexOf(' ') >= 0) {
            callback(errorInput.errorSpace)
            return
        }
        const validChar = /[0-9]+/g
        const invalidChars = value.replace(validChar, "")
        if(invalidChars) {
            callback(errorInput.errorChar)
            return
        }
        if(value.length < 13){
            callback(errorInput.errorLength)
            return
        }
    }

    const callbackSuccess = () => {
        
    }

    const callbackFinal = () => {
        const callbackResetFilterSuccess = () => {
            dispatch(getFilterUser({ filterFrom: '', filterSkip: 0, filterSize: FILTER_SIZE }))
        }
        dispatch(resetFilterUser(callbackResetFilterSuccess))
        dispatch(getCountUser())
        setFaceImg(null)
        setValueCIF("")
    }

    const _handleGenerateCIF = () => {
        let ticks = moment().valueOf()
        setValueCIF(`${ticks}`)
        if(cifRef.current){
            // console.log(ticks)
            cifRef.current.value = `${ticks}`
            cifRef.current.focus()
        }
    }

    const  _handleSubmitForm = async () =>{
        // console.log(valueCIF)
        const base64 = await fileToBase64(faceImg)
        const dataRequest = { 
            cif: valueCIF, 
            photo: base64.split(',')[1]
        }
        dispatch(enrollNewUser(dataRequest, callbackSuccess, null, callbackFinal))
    }

    const _handlePressCif = (e) => {
        if(e.key === 'Enter' && !isDisabledBtnRegister) {
            _handleSubmitForm()
        }
    }

    const _onScroll = (e) => {
        // if(!listRef.current) return
        // if(!listData || listData.length === 0) return
        // if(listRef.current.scrollTop >= listRef.current.scrollHeight - listRef.current.offsetHeight - 5) {
        //     console.log('ping')
        //     let skip = listData.length
        //     let from = listData[skip - 1].id
        //     let size = FILTER_SIZE
        //     dispatch(getFilterUser({ filterFrom: from, filterSkip: skip, filterSize: size }))
        // }
    }

    const tableContent = useMemo(() => (
        listData.length === 0 ?
        <div className="wrap-empty">
            <EmptyDataControl />
        </div>
        :
        <div className="wrap-list-trans" ref={listRef}
            onScroll={(e) => _onScroll(e)}>
            <div className="list-trans">
                {
                    listData.map((item, i) => (
                        <HistoryItem key={i} className='history-item'
                            item={item} />
                    ))
                }
            </div>
        </div>
    ), [JSON.stringify(listData)])

    return (
        <WrapEnroll>
            <PageHeader>Enrollment Information</PageHeader>
            <FormSubmitControl extendStyles={extendStylesEnrollForm}>
                <div className="wrap-content">
                    <div className="left-content">
                        <p className="form-title">Face Picture</p>
                        <DragAndDropImage 
                            file={faceImg}
                            handleRemoveFile={() => setFaceImg(null)}
                            handleDropFiles={files => setFaceImg(files[0])}
                        />
                    </div>
                    <div className="center-content">
                        <p className="form-title">Customer Number</p>
                        <Input 
                            ref={cifRef}
                            placeholder="Customer Number"
                            inputType="TEXT"
                            value={valueCIF}
                            required
                            onChange={(e) => setValueCIF(e.target.value)}
                            onKeyPress={_handlePressCif}
                            iconInput={iconUser}
                            handleValidationInput={_handleValidateInput}
                            className="input-enroll"
                            maxLength="13"
                        />
                        <Button 
                            text="Register"
                            iconBtn={<IcBtnRegister />}
                            className="btn-submit"
                            isDisable={isDisabledBtnRegister}
                            onClick={_handleSubmitForm}
                        />
                    </div>
                    <div className="right-content">
                        <Button 
                            text="Generate"
                            iconBtn={<IcGenerate />}
                            className="btn-generate"
                            onClick={_handleGenerateCIF}
                        />
                    </div>
                </div>
            </FormSubmitControl>
            <PageHeader>Search History</PageHeader>
            <TableHeaderEnroll>
                {
                    dataHeader.map((item, i) => (
                        <div className="table-header" key={i}>
                            {item}
                        </div>
                    ))
                }
            </TableHeaderEnroll>
            <TableContentEnroll>
                {tableContent}
            </TableContentEnroll>
        </WrapEnroll>
    )
}

export default Enroll
