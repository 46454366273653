import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from "react-router-dom";
import Axios from 'axios'

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import configureStore from "./redux/store"
import { MTOOLS_ADMIN_LOGIN } from './helper/localStore';

let initialStates = {}
const dataLogin = localStorage.getItem(MTOOLS_ADMIN_LOGIN)
if (dataLogin) {
  let login = JSON.parse(dataLogin)

  if (login.token) {
    Axios.defaults.headers.common['Authorization'] = `Bearer ${login.token}`
  }
  initialStates = {
    loginReducer: {
      loggedIn: true,
      dataLogin: JSON.parse(dataLogin)
    }
  }
}

const store = configureStore(initialStates);
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
