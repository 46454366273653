import Axios from "axios";
//host
import { host } from "../../constant/host"
//help
import checkIsSuccessAction from "../../helper/checkIsSuccessAction";
import mapErrors from "../../helper/mapErrors";

// action
import { disableLoading, enableLoading } from "../loading/action";

//type
import * as types from './types'


const apiEnrollNewUser = `${host}/facepay/enrollNewUser`

let isCalling = false
export const enrollNewUser = ({ cif, photo }, callbackSuccess, callbackError, callbackFinal) => async dispatch => {
    if(isCalling) return;
    isCalling = true
    dispatch(enableLoading())
    const dataRequest = JSON.stringify({
        cif,
        photo,
    })

    try {
        const res = await Axios.post(apiEnrollNewUser, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.ENROLL_NEW_USER_SUCCESS,
                payload: {
                    code: "CLIENT_ENROLL_SUCCESS"
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.ENROLL_NEW_USER_ERR,
                payload: data
            })
            callbackError && callbackError()
        }

    } catch (error) {
        dispatch(mapErrors(error, types.ENROLL_NEW_USER_ERR))    
    }
    dispatch(disableLoading())
    callbackFinal && callbackFinal()
    isCalling = false
}

export const resetEnrollResult = () => async dispatch => {
    dispatch({
        type: types.RESET_ENROLL_NEW_USER
    })
}

const apiGetCountUser = `${host}/facepay/countUser`

export const getCountUser = (callbackSuccess) => async dispatch => {


    try {
        const res = await Axios.post(apiGetCountUser)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_COUNT_USER,
                payload: data
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_COUNT_USER_ERR,
                payload: data
            })
        }

    } catch (error) {
        dispatch(mapErrors(error, types.GET_COUNT_USER_ERR))
    }
}






