import React, { useState, useEffect } from 'react'
import IcFacePrintDefault from '../../../../../../assets/images/IcFacePrintDefault';
import IcFindOne18x18 from '../../../../../../assets/images/IcFindOne18x18';
import { apiDownLoadAvatar, apiDownLoadAvatarByFaceSearch, apiDownLoadPhotoByFaceSearch } from '../../../../../../constant/host';
import { convertDateTime } from '../../../../../../helper/tools';

// component
import Portal from '../../../../../Control/Portal';
import ShowZoomFace from '../../Control/ShowZoomFace';

// styles
import { Bound, WrapItem } from './index.styles'

export default function HistoryItem(props) {
    const { className, item } = props

    const [isZoomFace, setIsZoomFace] = useState(false);
    const [isErrImg, setIsErrImg] = useState(false)
    const [isErrImgByFaceSearch, setIsErrImgByFaceSearch] = useState(false)

    useEffect(() => {
        setIsErrImg(false)
    }, [item?.id])

    useEffect(() => {
        setIsErrImgByFaceSearch(false)
    }, [item?.faceSearchId])

    const lstFaces = [
        ...(
            // !isErrImgByFaceSearch 
            // ? 
            [{
                imgUrl: apiDownLoadPhotoByFaceSearch(item.faceSearchId),
                imgTitle: "Face Uploaded"
            }] 
            // : []
        ),
        ...(
            !isErrImg && (item.isIdentical || item.isSame)
            ? [{
                imgUrl: apiDownLoadAvatar(item.cif),
                imgTitle: `Face in Database - ${Math.round((item?.confidence ?? 0) * 100) / 100}%`
            }]
            : []
        ),
    ]

    return (
        <Bound className={className}>
            <div className={`block-status-color ${item?.isIdentical === false && item?.isSame === false ? 'fail' : 'success'}`}></div>
            <div className='icon-arrow'><IcFindOne18x18 /></div>
            <WrapItem>
                {/* <div>{item.index}</div> */}
                <div className="wrap-avatar face">
                    {
                        isErrImgByFaceSearch ?
                            <IcFacePrintDefault className="avatar"
                                onClick={() => setIsZoomFace(true)} 
                            />
                            :
                            <img
                                src={item?.faceSearchId ? apiDownLoadAvatarByFaceSearch(item?.faceSearchId) : ""}
                                className="avatar"
                                alt="avatar"
                                onError={() => setIsErrImgByFaceSearch(true)}
                                onClick={() => setIsZoomFace(true)}
                            />
                    }
                </div>
                <div className="wrap-avatar face">
                    {   item?.isIdentical === false && item?.isSame === false ?
                        <></>
                        :
                        isErrImg ?
                            <IcFacePrintDefault className="avatar" />
                            :
                            <img
                                src={item?.cif ? apiDownLoadAvatar(item?.cif) : ""}
                                className="avatar"
                                alt="avatar"
                                onError={() => setIsErrImg(true)}
                                onClick={() => setIsZoomFace(true)}
                            />
                    }
                </div>
                <div>
                    {
                        item?.isIdentical === false && item?.isSame === false ?
                        ""
                        :
                        item?.cif || ""
                    }
                </div>
                <div>
                    {
                        item?.isIdentical === false && item?.isSame === false ?
                        ""
                        :
                        item?.enrollTime ? convertDateTime(item?.enrollTime, "DD/MM/YYYY") : ""}</div>
                <div>
                    {
                        item?.icaoIsError?
                            <p className="not-match">Issue : ICAO | Quality | Size</p>
                            :
                            item?.faceCount === 0 ?
                                <p className="not-match">No Face Found</p>
                                :
                                item?.faceCount > 1 ?
                                    <p className="not-match">Multiple Face Not Allowed</p>
                                    :
                                    item?.isIdentical === false && item?.isSame === false ?  
                                        <p className="not-match">Not Found in DB</p>
                                        :
                                        <p className="matched">Matched</p>
                    }
                </div>
                <div>
                    {
                        item?.isIdentical === false && item?.isSame === false ?
                        ""
                        :
                        item?.confidence !== undefined ? `${Math.round(item?.confidence * 100) / 100}%` : ""
                    }
                </div>
                <div>{item?.processingTime !== undefined ? `${Math.round(item?.processingTime * 100) / 100}ms` : ""}</div>
                <div>{item?.requestTime !== undefined ? convertDateTime(item?.requestTime, "DD/MM/YYYY") : ""}</div>
                <div>{item?.requestTime !== undefined ? convertDateTime(item?.requestTime, "HH:mm") : ""}</div>
            </WrapItem>
            {
                isZoomFace &&
                <Portal>
                    <ShowZoomFace
                        lstFaces={lstFaces}
                        onClose={() => setIsZoomFace(false)}
                    />
                </Portal>
            }
        </Bound >
    )
}
