import React, { useState, useRef, useEffect, useMemo } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'

// styles
import { WrapMatching11, extendStylesMatchingOneForm, TableHeaderMatching11, TableContentMatching11 } from './index.styles'
// component
import FormSubmitControl from '../Control/FormSubmitControl'
import PageHeader from '../Control/PageHeader'
import EmptyDataControl from '../Control/EmptyDataControl'
import HistoryItem from './HistoryItem'
import Input from '../../../../Control/Input'
import Button from '../../../../Control/Button'
import DragAndDropImage from '../../../../Control/DragAndDropImage'

//image
import IcInputUser from '../../../../../assets/images/IcInputUser'
import IcInputUserActive from '../../../../../assets/images/IcInputUserActive'
import IcInputUserError from '../../../../../assets/images/IcInputUserError'
import IcBtnSearch from '../../../../../assets/images/ICBtnSearch'
import IcFindOne from '../../../../../assets/images/IcFindOne'
import IcPictureUser from '../../../../../assets/images/IcPictureUser'
//data
import { errorInput } from '../../../../../data/errorInput'

// hooks
import useDebounce from '../../../../../hook/useDebounce'

// action
import { getUser } from '../../../../../redux/getUser/action'
import { verifyUser } from '../../../../../redux/verifyUser/action'
import { verifyFilterHistory, resetVerifyFilterHistory } from '../../../../../redux/verifyUser/verifyFilterHistory/action'

// api
import { apiDownLoadAvatar } from '../../../../../constant/host'

// helper
import fileToBase64 from '../../../../../helper/fileToBase64'
import * as tools from '../../../../../helper/tools'

const dataHeader = [
    // "#",
    "Faceprint",
    "CIF Faceprint",
    "Customer Number",
    "Enroll Date",
    "Status",
    "Confidence Score",
    "Processing Time",
    "Requested Date",
    "Time"
]

const dataMock = [
    {status: 'fail'},
    {status: 'success'},
    {status: 'success'},
    {status: 'success'},
    {status: 'fail'},
    {status: 'success'},
    {status: 'success'},
    {status: 'success'},
    {status: 'fail'},
    {status: 'fail'}
]

const iconUser = {
    icon: IcInputUser,
    iconActive: IcInputUserActive,
    iconError: IcInputUserError,
}

const Matching11 = () => {
    const dispatch = useDispatch()
    const listRef = useRef(null)

    const [listData, setListData] = useState([])
    const [valueCIF, setValueCIF] = useState("")
    const [checkForm, seCheckForm] = useState(false)
    const [isDisabledBtnMatch, setIsDisabledBtnMatch] = useState(true);
    const [faceImg, setFaceImg] = useState(null);
    const [faceUrlByCif, setFaceUrlByCif] = useState("");

    const cifNum = useDebounce(valueCIF, 500)

    const { verifyFilterHistoryReducer } = useSelector(state => ({
        verifyFilterHistoryReducer: state.verifyFilterHistoryReducer
    }))

    useEffect(() => {
        dispatch(verifyFilterHistory({ filterFrom: '', filterSkip: 0, filterSize: -1 }))
        return () => {
            
        }
    }, [])

    useEffect(() => {
        if(!verifyFilterHistoryReducer?.histories) return
        let list = verifyFilterHistoryReducer.histories
        
        for (let i = 0; i < verifyFilterHistoryReducer.histories.length; i++) {
            let item = verifyFilterHistoryReducer.histories[i];
            let index = verifyFilterHistoryReducer.histories.length - i
            item.index = tools.formatStringID(index, 6);
        }
        if(list) setListData(list)
        return () => {
            
        }
    }, [JSON.stringify(verifyFilterHistoryReducer)])

    const _handleValidateInput = (value,callback) =>{
        if (!value.trim() || 0 === value.length) {
            callback(errorInput.errorEmpty)
            return
        }
        if (value.indexOf(' ') >= 0) {
            callback(errorInput.errorSpace)
            return
        }
        const validChar = /[0-9]+/g
        const invalidChars = value.replace(validChar, "")
        if(invalidChars) {
            callback(errorInput.errorChar)
            return
        }
        if(value.length < 13){
            callback(errorInput.errorLength)
            return
        }
    }

    const callbackVerifySuccess = () => {
        
    }

    const callbackVerifyFinal = () => {
        const callbackResetFilterSuccess = () => {
            dispatch(verifyFilterHistory({ filterFrom: '', filterSkip: 0, filterSize: -1 }))
        }
        dispatch(resetVerifyFilterHistory(callbackResetFilterSuccess))
        
        setValueCIF("")
        setFaceImg(null)
        setFaceUrlByCif("")
    }

    const _handleSubmitForm = async () => {
        const base64 = await fileToBase64(faceImg)

        const dataRequest = { 
            cif: cifNum, 
            photo: base64.split(",")[1]
        }
        dispatch(verifyUser(dataRequest, callbackVerifySuccess, null, callbackVerifyFinal))
    }

    const callbackSuccess = async () => {
        try {
            const res = await axios.get(apiDownLoadAvatar(cifNum), {
                responseType: 'blob'
            })
            const { data } = res;
            const urlImg = URL.createObjectURL(data)
            setFaceUrlByCif(urlImg) 
        }
        catch(err) {
            return;
        }
    }

    useEffect(() => {
        if(cifNum.length !== 13) return;
        dispatch(getUser({ cif: cifNum }, callbackSuccess))
    }, [cifNum]);

    useEffect(() => {
        const isEnable = cifNum.length === 13 && !!faceImg && !!faceUrlByCif
        setIsDisabledBtnMatch(!isEnable)
    }, [cifNum, faceImg]);

    useEffect(() => {
        return () => {
            URL.revokeObjectURL(faceUrlByCif)
        }
    }, []);

    const tableContent = useMemo(() => (
        listData.length === 0 ?
        <div className="wrap-empty">
            <EmptyDataControl />
        </div>
        :
        <div className="wrap-list-trans" ref={listRef}>
            <div className="list-trans">
                {
                    listData.map((item, i) => (
                        <HistoryItem key={i} className='history-item'
                            item={item} />
                    ))
                }
            </div>
        </div>
    ), [JSON.stringify(listData)])

    return (
        <WrapMatching11>
            <PageHeader>Matching Information</PageHeader>
            <FormSubmitControl extendStyles={extendStylesMatchingOneForm}>
            <div className="wrap-content">
                <div className="cif-content">
                    <p className="form-title">Customer Number</p>
                    <Input 
                        placeholder="Customer Number"
                        inputType="TEXT"
                        value={valueCIF}
                        required
                        onChange={(e) => setValueCIF(e.target.value)}
                        iconInput={iconUser}
                        handleValidationInput={_handleValidateInput}
                        className="input-matching"
                        maxLength="13"
                    />
                </div>
                <div className="facePrint-content">
                    <p className="form-title">CIF Faceprint</p>
                    {
                        !!faceUrlByCif 
                        ? (
                            <div className="facePrint-image">
                                <img src={faceUrlByCif} alt="face by cif" />
                            </div>
                        )
                        : (
                            <div className="facePrint-image default">
                                <IcPictureUser className="icon_faceUser"/>
                            </div>
                        )
                    }
                </div>
                <div className="icon-reset">
                    <IcFindOne />
                </div>
                <div className="facePicture-content">
                    <p className="form-title">Face Picture</p>
                    <div className="form-content">
                        <div className="facePicture-image">
                            <DragAndDropImage 
                                file={faceImg}
                                handleRemoveFile={() => setFaceImg(null)}
                                handleDropFiles={files => setFaceImg(files[0])}
                            />
                        </div>
                        <Button 
                            text="Match"
                            iconBtn={<IcBtnSearch />}
                            className="btn-submit"
                            isDisable={isDisabledBtnMatch}
                            onClick={_handleSubmitForm}
                        />
                    </div>
                </div>
            </div>
            </FormSubmitControl>
            <PageHeader>Search History</PageHeader>
            <TableHeaderMatching11>
                {
                    dataHeader.map((item, i) => {
                        let style = {}
                        switch (i) {
                            case 1:
                                style = {paddingLeft: '5px'}
                                break;
                            case 2:
                                // style = {paddingLeft: '8px'}
                                break;
                        
                            default:
                                break;
                        }
                        return (
                            <div className="table-header" key={i} style={style}>
                                {item}
                            </div>
                        )
                    })
                }
            </TableHeaderMatching11>
            <TableContentMatching11>
                {tableContent}
            </TableContentMatching11>
        </WrapMatching11>
    )
}

export default Matching11
