import React from 'react'
import { Route, Switch } from 'react-router-dom'

// styles
import { WrapBreadcrumbAndTool } from './index.styles'

// component
import Breadcrumb from './Breadcrumb'

// data
import dataMenu from '../../../../data/dataMenu';

const BreadcrumbAndTool = () => {

    return (
        <WrapBreadcrumbAndTool>
            <div className='wrap-breadcrumb-and-tool'>
                <Switch>
                    {
                        dataMenu.map(({ path, name }) => (
                            <Route path={path} key={name}>
                                <Breadcrumb arrPath={[{ value: name }]} />
                            </Route>
                        ))
                    }
                </Switch>
            </div>
        </WrapBreadcrumbAndTool>
    )
}

export default BreadcrumbAndTool
