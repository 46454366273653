import styled from 'styled-components'

const OverlayFullScreen = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100vh;
    min-width: 100vw;
    width: 1280px;
    height: 800px;
    background: #22222280;
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: center;
`

const WrapShowZoomFace = styled.div`
    position: relative;
    display: flex;

    .close {
        position: absolute;
        bottom: calc(100% + 9px);
        right: 0;

        &:hover {
            cursor: pointer;
        }
    }

    .wrap-img {
        width: 480px;
        height: 480px;
        background: gray;
        position: relative;
        img {
            border-radius: 3px;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        p {
            position: absolute;
            bottom: 0;
            min-height: 69px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(180deg, rgba(49, 49, 49, 0) 0%, #313131 100%);
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.01em;
            color: #FFFFFF;
        }

        &:last-child {
            margin-left: 40px;
        }
    }

`;

export {
    OverlayFullScreen,
    WrapShowZoomFace
}