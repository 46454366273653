import React, { useState } from 'react'
import IcFacePrintDefault from '../../../../../../assets/images/IcFacePrintDefault';
import IcFindOne18x18 from '../../../../../../assets/images/IcFindOne18x18';
import { apiDownLoadAvatarByFaceSearch, apiDownLoadPhotoByFaceSearch } from '../../../../../../constant/host';
import { convertDateTime } from '../../../../../../helper/tools';

// component
import Portal from '../../../../../Control/Portal';
import ShowZoomFace from '../../Control/ShowZoomFace';

// styles
import { Bound, WrapItem } from './index.styles'

// const listFacesMock = [
//     {
//         imgUrl: "https://picsum.photos/480/480",
//         imgTitle: "Face Picture 1"
//     },
//     {
//         imgUrl: "https://picsum.photos/480/480",
//         imgTitle: "Face Picture 2"
//     },
// ]

export default function HistoryItem(props) {
    const { className, item, index } = props

    const { 
        faceSearchId, 
        faceCompareId, 
        isIdentical, 
        isSame,
        confidence,
        processingTime,
        requestTime,
        faceCount,
        icaoIsError
    } = item

    const [isZoomFace, setIsZoomFace] = useState(false);
    const [isErrImgByFaceSearch, setIsErrImgByFaceSearch] = useState(false)
    const [isErrImgByFaceCompare, setIsErrImgByFaceCompare] = useState(false);

    const lstFaces = [
        ...(
            // !isErrImgByFaceSearch 
            // ? 
            [{
                imgUrl: apiDownLoadPhotoByFaceSearch(faceSearchId),
                imgTitle: "Face Picture 1"
            }] 
            // : []
        ),
        ...(
            // !isErrImgByFaceCompare
            // ? 
            [{
                imgUrl: faceCompareId ? apiDownLoadPhotoByFaceSearch(faceCompareId) : "",
                imgTitle: "Face Picture 2"
            }]
            // : []
        ),
    ]

    return (
        <Bound className={className}>
            <div className={`block-status-color ${!isIdentical && !isSame?'fail':'success'}`}></div>
            <div className='icon-arrow'><IcFindOne18x18 /></div>
            <WrapItem>
                {/* <div>{("0".repeat(6) + index).slice(-6)}</div> */}
                <div className="wrap-avatar face">
                    {
                        isErrImgByFaceSearch ?
                            <IcFacePrintDefault className="avatar"
                                onClick={() => setIsZoomFace(true)}
                            />
                            :
                            <img
                                src={faceSearchId ? apiDownLoadAvatarByFaceSearch(faceSearchId) : ""}
                                className="avatar"
                                alt="avatar"
                                onError={() => setIsErrImgByFaceSearch(true)}
                                onClick={() => setIsZoomFace(true)}
                            />
                    }
                </div>
                <div className="wrap-avatar face">
                    {
                        isErrImgByFaceCompare ?
                            <IcFacePrintDefault className="avatar" 
                                onClick={() => setIsZoomFace(true)}
                            />
                            :
                            <img
                                src={faceCompareId ? apiDownLoadAvatarByFaceSearch(faceCompareId) : ""}
                                className="avatar"
                                alt="avatar"
                                onError={() => setIsErrImgByFaceCompare(true)}
                                onClick={() => setIsZoomFace(true)}
                            />
                    }
                </div>
                <div>
                    {
                        icaoIsError?
                            <p className="not-match">Issue : ICAO | Quality | Size</p>
                            :
                            faceCount === 0 ?
                                <p className="not-match">No Face Found</p>
                                :
                                faceCount > 1 ?
                                    <p className="not-match">Multiple Face Not Allowed</p>
                                    :
                                    isIdentical === false && isSame === false ?  
                                        <p className="not-match">Not Matched</p>
                                        :
                                        <p className="matched">Matched</p>
                    }
                </div>
                <div>
                    {
                        faceCount === 1 &&
                        confidence !== undefined ? `${Math.round(confidence * 100)/ 100}%` : ""
                    }
                </div>
                <div>{processingTime !== undefined ? `${Math.round(processingTime * 100)/ 100}ms` : ""}</div>
                <div>{requestTime !== undefined ? convertDateTime(requestTime, "DD/MM/YYYY") : ""}</div>
                <div>{requestTime !== undefined ? convertDateTime(requestTime, "HH:mm") : ""}</div>
            </WrapItem>
            {
                isZoomFace &&
                <Portal>
                    <ShowZoomFace 
                        lstFaces={lstFaces}
                        onClose={() => setIsZoomFace(false)}
                    />
                </Portal>
            }
        </Bound>
    )
}
