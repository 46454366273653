import React, { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

// styles
import { WrapHeader } from './index.styles'

// images
// import LogoFacepay from '../../../assets/images/LogoFacepay'
import IcUser from '../../../assets/images/IcUser'
import IcLogOut from '../../../assets/images/IcLogOut'
import wee_logo from '../../../assets/images/wee_logo.svg'
//hook
import { useOnClickOutside } from '../../../hook/useClickOutside'

// constant
import { MAIN } from '../../../constant/path'

//action
import { logoutAction } from '../../../redux/login/action'

const Header = (props) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const refDropdown = useRef(null)

    const [isShow, setIsShow] = useState(false)
    const [fullname, setFullname] = useState('')

    const { loginReducer } = useSelector(state => ({
        loginReducer: state.loginReducer
    }))

    useEffect(() => {
        if(!loginReducer?.dataLogin) return
        if(loginReducer.dataLogin?.fullname && loginReducer.dataLogin?.fullname.length > 0){
            setFullname(loginReducer.dataLogin.fullname)
        }
        return () => {
            
        }
    }, [JSON.stringify(loginReducer)])

    useOnClickOutside(refDropdown, () => {
        setIsShow(false)
    })

    const _handleLogOut = () => {
        dispatch(logoutAction(history, true))
    }
    return (
        <WrapHeader {...props} ref={refDropdown}>
            <div className='wrap-header'>
                <Link to={`/${MAIN}`} className="logo-facepay">
                    <img src={wee_logo} alt='wee logo' className='wee-logo'/>
                </Link>
                <div className="user">
                    <p className="user_name">{fullname ? fullname : '-'}</p>
                    <IcUser onClick={() => setIsShow(!isShow)} />
                    {
                        isShow &&
                        <div className="popup-logout" onClick={_handleLogOut}>
                            <IcLogOut />
                            <p>Sign out</p>
                        </div>
                    }
                </div>
            </div>
        </WrapHeader>
    )
}

export default Header
