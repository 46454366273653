import moment from 'moment'

export const convertDateTime = (time, format) => {
    return moment(time).format(format);
};

export const formatStringID = (value, length) => {
    while (value.toString().length < length) {
        value = `0${value}`
    }
    return value
}



