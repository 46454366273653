import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

// styles
import { WrapPage } from './index.styles'

// constant
import * as path from '../../../../constant/path'

// component
import Enroll from './Enroll'
import Matching1N from './Matching1N'
import Matching11 from './Matching11'
import FaceComparison from './FaceComparison'
import Partner from './Partner'

const Page = (props) => {
    return (
        <WrapPage {...props}>
            <Switch>
                <Route path={`/${path.MAIN}/${path.ENROLL}`}>
                    <Enroll />
                </Route>
                <Route path={`/${path.MAIN}/${path.MATCHING_1_N}`}>
                    <Matching1N />
                </Route>
                <Route path={`/${path.MAIN}/${path.MATCHING_1_1}`}>
                    <Matching11 />
                </Route>
                <Route path={`/${path.MAIN}/${path.FACE_COMPARISON}`}>
                    <FaceComparison />
                </Route>
                <Route path={`/${path.MAIN}/${path.PARTNER}`}>
                    <Partner />
                </Route>
                <Redirect to={`/${path.MAIN}/${path.ENROLL}`} />
            </Switch>
        </WrapPage>
    )
}

export default Page
