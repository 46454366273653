import styled from 'styled-components'

const WrapHeader = styled.div`
    width: 100%;
    height: 60px;
    background: #313131;
    .wrap-header{
        display: flex;
        max-width: 1280px;
        box-sizing: border-box;
        height: 100%;
        align-items: center;
        padding: 0px 30px;
        justify-content: space-between;
        
        .logo-facepay {
            display: flex;
            height: 32px;
            &:hover {
                cursor: pointer;
            }
        }

        .user{
            display: flex;
            align-items: center;
            position: relative;
            .user_name{
                font-size: 13px;
                line-height: 18px;
                font-family: "Open Sans";
                color: #FFFFFF;
                padding-right: 10px;
                letter-spacing: -0.01em;
            }


            &:hover {
                cursor: pointer;
            }
        }

        .popup-logout{
            position: absolute;
            top: 33px;
            right: 0;
            width: 140px;
            height: 48px;
            background: #FFFFFF;
            box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
            display: flex;
            align-items: center;
            padding: 0 12px;
            box-sizing: border-box;
            border-radius: 5px;
            p{
                font-family: "Open Sans";
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 18px;
                color: #313131;
                padding-left: 4px;
            }
        }
    }
    
`;

export {
    WrapHeader
}