import Axios from "axios";
//host
import { host } from "../../../constant/host"
//help
import checkIsSuccessAction from "../../../helper/checkIsSuccessAction";
import mapErrors from "../../../helper/mapErrors";
//type
import * as types from './types'

const apiCompareFilterHistory = `${host}/facepay/compare/filterHistory`

let isCalling = false
export const compareFilterHistory = ({ filterFrom, filterSkip, filterSize }, callbackSuccess) => async dispatch => {
    if(isCalling) return;
    isCalling = true

    const condFilters = {
        filterFrom,
        filterSkip,
        filterSize,
    }

    const dataRequest = JSON.stringify({
        filterFrom,
        filterSkip,
        filterSize,
    })

    try {
        const res = await Axios.post(apiCompareFilterHistory, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.COMPARE_FILTER_HISTORY,
                payload: {
                    histories: data.histories || [],
                    condFilters
                }
                
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.COMPARE_FILTER_HISTORY_ERR,
                payload: data
            })
        }

    } catch (error) {
        dispatch(mapErrors(error, types.COMPARE_FILTER_HISTORY_ERR))    
    }
    isCalling = false
}

export const resetCompareFilterHistory = (callbackSuccess) => async dispatch => {
    dispatch({
        type: types.RESET_COMPARE_FILTER_HISTORY
    })
    callbackSuccess && callbackSuccess()
}