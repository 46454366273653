import * as types from './types'

const initialState = {
    // "TEST_SUCCESS": {
    //     type: "TEST_SUCCESS",
    //     payload: {
    //         code: "TEST_SUCCESS"
    //     }
    // },
    // "TEST_ERR": {
    //     type: "TEST_ERR",
    //     payload: {
    //         code: "TEST_ERR"
    //     }
    // }
}

const notifies = (state = initialState, action) => {
    const actionError = /.*_ERR/.test(action.type)
    const actionTimeout = types.LOGOUT_BY_TIMEOUT === action.type
    const actionSuccess = /.*_SUCCESS/.test(action.type)
    if(actionError || actionTimeout || actionSuccess){
        return {
            ...state,
            [action.type]: action
        }
    }
    if(action.type === types.POP_NOTIFY) {
        const copyState = {...state};
        // const firstItem = Object.values(copyState)[0]
        // if(!firstItem) return state;
        // delete copyState[firstItem.type]
        delete copyState[action.payload.typeItem]
        return copyState;
    }
    return state;
}

export default notifies