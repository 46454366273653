import React, { useState, useRef } from 'react'

//style
import { InputWrapper } from './index.styles'

//image
import IcInputEyeOpen from '../../../assets/images/IcInputEyeOpen'
import IcInputEye from '../../../assets/images/IcInputEye'

const PWD = "PWD"
const PWD_ACTIVE = "PWD_ACTIVE"
const PWD_ERROR = "PWD_ERROR"

const InputPassword = ({ iconInput, inputType, error, handleValidationUPassword, ...props }) => {
    const [isShowPwd, setIsShowPwd] = useState(true);
    const [typeInput,setTypeInput] = useState(inputType)
    const [errorInput,setErrorInput] = useState("")
    const [errorBorder,setErrorBorder] = useState(false) 

    const inputEl = useRef(null);

    // handle show click eye
    const _toggleEye = () =>{
        setIsShowPwd(pre => !pre)
        isShowPwd ? inputEl.current.type = "text" : inputEl.current.type = "password"
    } 

    const _handleFocusInput = () =>{
        setErrorInput("")
        setErrorBorder(false)
        switch(typeInput){
            case PWD:
                setTypeInput(PWD_ACTIVE)
                break;
            case PWD_ERROR:
                setTypeInput(PWD_ACTIVE)
                break;
            default: return;
        }
    }

    const _handleBlurInput = () =>{
        if(typeInput === PWD_ACTIVE){
            setTypeInput(PWD)
            const callback = (textErrorInput) =>{
                setErrorInput(textErrorInput)
                setErrorBorder(true)
                setTypeInput(PWD_ERROR)
            }
            handleValidationUPassword && handleValidationUPassword(props.value,callback)
        }
    }

    //handle typeInput show icon
    const handleTypeInput = (type) =>{
        if(!iconInput) return
        switch(type){
            case PWD:
                return <iconInput.icon className="icon-left"/>
            case PWD_ACTIVE:
                return <iconInput.iconActive className="icon-left"/>
            case PWD_ERROR: 
                return <iconInput.iconError className="icon-left"/>       
            default: return;
        }
    }
    const iconTypeInput = handleTypeInput(typeInput)
    
    return (
        <InputWrapper>
            <div className="wrap-input">
                {   iconInput &&
                    iconTypeInput 
                }
                <input 
                    {...props} 
                    ref={inputEl} 
                    onFocus={_handleFocusInput}
                    onBlur={_handleBlurInput}
                    className={`${errorBorder ? "error-input" : ""}`}
                />
                {
                    inputEl.current?.type === "text" 
                    ? <IcInputEyeOpen className="icon-right" onClick={_toggleEye} /> 
                    : <IcInputEye className="icon-right" onClick={_toggleEye} />
                }
            </div>
            {
                errorInput &&
                <div className="error">{errorInput}</div>
            }
        </InputWrapper>
    )
}

export default InputPassword
