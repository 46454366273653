import styled from 'styled-components'

const WrapBreadcrumbAndTool = styled.div`
    height: 64px;
    background: #FFFFFF;
    box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
    
    .wrap-breadcrumb-and-tool{
        width: calc(1280px - 100px);
        box-sizing: border-box;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;
        
    }
`;

export {
    WrapBreadcrumbAndTool
}