import moment from 'moment'

// export const host = "https://matching.facepay.vn/tpb-matching"
// export const host = "https://mtool.facepay.vn/tpb-matching"
export const host = "https://mtool.weedigital.vn/matching"

export const apiDownLoadAvatar = (cif = "") => {
    return `${host}/media/dowload/avatar?cif=${cif}&time=${moment().unix()}`;
};

export const apiDownLoadAvatarByFaceSearch = (id = "") => {
    return `${host}/media/download/avatarByFaceSearchId?id=${id}&time=${moment().unix()}`;
};

export const apiDownLoadPhotoByFaceSearch = (id = "") => {
    return `${host}/media/download/photoByFaceSearchId?id=${id}&time=${moment().unix()}`;
};

export const apiGetLogoBank = () => {
    return `${host}/media/assets/logo/TPB.png`;
};