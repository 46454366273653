import React from 'react'

const IcFindOneActive = ({...props}) => {
    return (
        <svg {...props} width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 7.53554H22.0711" stroke="#33ADFF" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.7678 3.23218L22.0711 7.53548L17.7678 11.8388" stroke="#33ADFF" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.0711 16.5355H2.99998" stroke="#33ADFF" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.30334 12.2322L3.00004 16.5355L7.30334 20.8388" stroke="#33ADFF" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcFindOneActive
