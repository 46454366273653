import * as types from './types'

const intitialState = {
    histories: [],
    condFilters: {}
}

const verifyFilterHistoryReducer = (state = intitialState, action) => {
    switch (action.type) {        
        case types.VERIFY_FILTER_HISTORY: {
            const { histories, ...payload } = action.payload
            return {
                ...state,
                ...payload,
                // histories: [...state.histories, ...histories],
                histories
            }
        }
        case types.VERIFY_FILTER_HISTORY_ERR: {
            return intitialState
        } 
        case types.RESET_VERIFY_FILTER_HISTORY: {
            return intitialState
        }     
        default:
            return state;
    }

}

export default verifyFilterHistoryReducer