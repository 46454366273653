import React, { useState, useReducer, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { sha256 } from 'js-sha256';
import { useSelector } from 'react-redux';

// styles
import { WrapLogin } from './index.styles'
//component
import Input from '../Control/Input';
import InputPassword from '../Control/InputPassword';
import Button from '../Control/Button';
// action
import { loginActicon } from '../../redux/login/action';

// constant
import { MAIN } from '../../constant/path';

// image
// import LogoLogin from '../../assets/images/LogoLogin'
// import BackgroundLogin from '../../assets/images/bg_login.png'
import IcButtonLogin from '../../assets/images/IcButtonLogin'
import IcInputUser from '../../assets/images/IcInputUser';
import IcInputUserActive from '../../assets/images/IcInputUserActive'
import IcInputUserError from '../../assets/images/IcInputUserError'
import IcInputLock from '../../assets/images/IcInputLock'
import IcInputLockError from '../../assets/images/IcInputLockError'
import IcInputLockActive from '../../assets/images/IcInputLockActive'
import wee_logo from '../../assets/images/LogoLogin/wee_logo.svg'
import home_retail_cover from '../../assets/images/home_retail_cover.png'
import wee_product_tag_line from '../../assets/images/wee_product_tag_line.svg'


//data
import { errorInput } from '../../data/errorInput';

const iconUser = {
    icon: IcInputUser,
    iconActive: IcInputUserActive,
    iconError: IcInputUserError,
}
const iconPassword = {
    icon: IcInputLock,
    iconActive: IcInputLockActive,
    iconError: IcInputLockError,
}

const Login = () => {

    const history = useHistory();
    const dispatch = useDispatch()

    const { loggedIn } = useSelector(state => ({
        loggedIn: state.loginReducer.loggedIn
    }))

    const [textErrorForm,setTextErrorForm] = useState("")
    const [stateForm, setStateForm] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            username: "",
            pwd: "",
        }
    )

    const handleChangeValue = (event) => {
        const { name, value } = event.target;
        setStateForm({
            [name]: value
        })
    }

    const SubmitFormSuccess = () =>{
        console.log("success")
        setTextErrorForm("")
    }

    const SubmitFormError = () =>{
        console.log("error")
        setTextErrorForm("Username or password is incorrect")
    }

    const submitForm = (e) => {
        e.preventDefault();
        // console.log(stateForm)
        setStateForm({
            username: stateForm.username,
            pwd: stateForm.pwd,
        })
        const dataLogin = {
            username: stateForm.username,
            pwd: sha256(stateForm.pwd),
        };
        dispatch(loginActicon(history, dataLogin,SubmitFormSuccess,SubmitFormError))
    }

    useEffect(() => {
        if(!loggedIn) return;
        history.push(`/${MAIN}`)

    }, [loggedIn]);

    const _handleValidationUser = (value, callback) =>{
        if (!value.trim() || 0 === value.length) {
            callback("Account cannot be empty")
            return
        }
        if (value.indexOf(' ') >= 0) {
            callback("Account does not contain whitespace")
            return
        }
    }

    const _handleValidationUPassword = (value, callback) =>{
        if(value.length === 0){
            callback("Password cannot be empty")
        }
    }

    return (
        <WrapLogin>
            <div className="wrap-inner-login">
                <div className="login-form">
                    {/* <LogoLogin className="icon-logo" /> */}
                    <img src={wee_logo} alt='wee logo' className='icon-logo'/>
                    <h2 className="title">Welcome back</h2>
                    <p className="note">Login your account</p>
                    <form className="form" onSubmit={e => e.preventDefault()} encType="multipart/form-data">
                        <Input
                            placeholder="Username"
                            inputType="TEXT"
                            name="username"
                            value={stateForm.username}
                            required
                            onChange={handleChangeValue}
                            iconInput={iconUser}
                            handleValidationInput={_handleValidationUser}
                        />
                        <InputPassword
                            placeholder="Password"
                            type="password"
                            inputType="PWD"
                            name="pwd"
                            required
                            value={stateForm.pwd}
                            onChange={handleChangeValue}
                            iconInput={iconPassword}
                            handleValidationUPassword={_handleValidationUPassword}
                        />
                        <div className="error">{textErrorForm}</div>
                        <Button
                            // type="submit" 
                            text="Login"
                            iconBtn={<IcButtonLogin />}
                            onClick={submitForm}
                            className="btn-login"
                        />

                    </form>
                </div>
                <div className="bg_login">
                    <p>Faceprint Testing Tool</p>
                    <img src={wee_product_tag_line} alt="craft-with" className='craft-with'/>
                    <img src={home_retail_cover} alt="bg_login" />
                </div>
            </div>
        </WrapLogin>
    )
}

export default Login
