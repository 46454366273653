import React, { useMemo, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

// action
import { popNotify } from '../../../redux/notifies/action'
import { logoutAction } from '../../../redux/login/action'

// constant
import { AUTHENTICATED_ERR } from '../../../redux/notifies/types'

// styles
import { WrapNotify } from './index.styles'

// component
import Toast from './Toast'
import Toast1107 from './Toast1107.js'

// constant
import { toastTypes } from './Toast/index.styles'

const messages = {
    TEST_SUCCESS: "TEST_SUCCESS",
    TEST_ERR: "TEST_ERR",
    CLIENT_MAX_SIZE: "Error - Image size max 3Mb",
    CLIENT_ENROLL_SUCCESS: "Enroll Successful",
    10: "Error - Can not find any matching",
    19: "Error - Face already registered",
    21: "Error - Faces not matched",
    1102: "Error - Customer number is duplicated",
    1103: "Error - Customer number is not exist",
    1105: "Error - No Face Found or Image invalid",
    1106: "Error - Multiple Face Not Allowed",
    1107: "Error - Picture is not ISO/ICAO Compliant > [Size or Quality issue]"
}

const Notify = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const { notifiesReducer } = useSelector(state => ({
        notifiesReducer: state.notifiesReducer
    }))
    const [currNotify, setCurrNotify] = useState(null);

    const _handleClosePopup = () => {
        setCurrNotify(null)
        dispatch(popNotify(currNotify.type))
    }

    useEffect(() => {
        if(currNotify) return;
        setCurrNotify(Object.values(notifiesReducer)[0])
    }, [notifiesReducer]);

    useEffect(() => {
        if(!currNotify) return;
        if(currNotify.type !== AUTHENTICATED_ERR) return;
        dispatch(logoutAction(history, false))
    }, [currNotify]);

    const notify = useMemo(() => {
        if(!currNotify) return null;

        const type = (() => {
            const isSuccess = /.*_SUCCESS/.test(currNotify.type)
            if(isSuccess)
                return toastTypes.SUCCESS
            
            // const isError = /.*_ERR/.test(currNotify.type)
            // if(isError) 
            return toastTypes.ERROR
        })()

        const code = currNotify?.payload?.code
        if(code === 1107) {
            return <Toast1107 
                onClose={_handleClosePopup}
            />
        }
        
        const message = (() => {
            return messages[code] /* || "Something error. Please try again!" */
        })()
        if(!message) return null

        return <Toast type={type} onClose={_handleClosePopup}>{message}</Toast>
    }, [currNotify])

    return (
        <WrapNotify>
            {notify}
        </WrapNotify>
    )
}

export default Notify
