
import { combineReducers } from "redux";

// reducer
import loginReducer from './login'
import notifiesReducer from './notifies'
import enrolReducer from './enrol'
import identifyReducer from './identifyUser'
import verifyReducer from './verifyUser'
import compareUserReducer from './compareUser'
import getUserReducer from './getUser'
import loadingReducer from './loading'
import filterUserReducer from './enrol/filterUser/filterUserReducer'
import identifyFilterHistoryReducer from './identifyUser/identifyFilterHistory/identifyFilterHistoryReducer'
import verifyFilterHistoryReducer from './verifyUser/verifyFilterHistory/verifyFilterHistoryReducer'
import compareFilterHistoryReducer from './compareUser/compareFilterHistory/compareFilterHistoryReducer' 

// types
import { LOGOUT } from "./login/types";

const app = combineReducers({
  loginReducer,
  notifiesReducer,
  enrolReducer,
  identifyReducer,
  verifyReducer,
  compareUserReducer,
  getUserReducer,
  filterUserReducer,
  identifyFilterHistoryReducer,
  verifyFilterHistoryReducer,
  compareFilterHistoryReducer,
  loadingReducer
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) state = undefined

  return app(state, action);
};

export default rootReducer;