import React from 'react'

// styles
import { WrapPageHeader } from './index.styles'

//image
import IcArrowDown from '../../../../../../assets/images/IcArrowDown'

const PageHeader = ({ children }) => {
    return (
        <WrapPageHeader>
            <IcArrowDown /> 
            <p className="title">{children}</p>
        </WrapPageHeader>
    )
}

export default PageHeader
