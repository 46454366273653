import React from 'react'

const IcLoading = (props) => {
    return (
        <svg {...props} width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6667 7.99998C14.6667 6.59914 14.2254 5.23389 13.4055 4.09805C12.5856 2.96222 11.4288 2.11351 10.0992 1.67242C8.76961 1.23133 7.33486 1.22028 5.99864 1.64083C4.66241 2.06137 3.49261 2.89215 2.65531 4.01522C1.81801 5.13829 1.35575 6.49658 1.33417 7.89725C1.31258 9.29793 1.73276 10.6698 2.53506 11.8181C3.33736 12.9665 4.481 13.8329 5.80363 14.2944C7.12626 14.756 8.56067 14.7891 9.90322 14.3892" stroke="#33ADFF" />
        </svg>
    )
}

export default IcLoading
