import React, { useEffect, useState, useRef, useReducer, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// styles
import { WrapFaceComparison, TableHeaderFaceComparison, TableContentFaceComparison, extendStylesFormFaceCompare } from './index.styles'
// component
import FormSubmitControl from '../Control/FormSubmitControl'
import PageHeader from '../Control/PageHeader'
import EmptyDataControl from '../Control/EmptyDataControl'
import HistoryItem from './HistoryItem'
import DragAndDropImage from '../../../../Control/DragAndDropImage'
import IcBtnSearch from '../../../../../assets/images/ICBtnSearch'
import Button from '../../../../Control/Button'
import IcFindOne from '../../../../../assets/images/IcFindOne'

// helper
import fileToBase64 from '../../../../../helper/fileToBase64'
import * as tools from '../../../../../helper/tools'

// action
import { getCompareUser } from '../../../../../redux/compareUser/action'
import { compareFilterHistory, resetCompareFilterHistory } from '../../../../../redux/compareUser/compareFilterHistory/action'

const dataHeader = [
    // "#",
    "Faceprint 1",
    "Faceprint 2",
    "Status",
    "Confidence Score",
    "Processing Time",
    "Requested Date",
    "Time"
]

const dataMock = [
    {status: 'success'},
    {status: 'fail'},
    {status: 'success'},
    {status: 'success'},
    {status: 'success'},
    {status: 'fail'},
    {status: 'success'},
    {status: 'fail'},
    {status: 'success'},
    {status: 'fail'}
]

const FaceComparison = () => {
    const dispatch = useDispatch()
    const listRef = useRef(null)

    const [listData, setListData] = useState([])
    const [facePictures, setFacePictures] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            pic1: null,
            pic2: null
        }
    )

    const [isDisabledBtnSearch, setIsDisabledBtnSearch] = useState(true);

    const { compareFilterHistoryReducer } = useSelector(state => ({
        compareFilterHistoryReducer: state.compareFilterHistoryReducer
    }))

    useEffect(() => {
        dispatch(compareFilterHistory({ filterFrom: '', filterSkip: 0, filterSize: -1 }))
        return () => {
            
        }
    }, [])

    useEffect(() => {
        if(!compareFilterHistoryReducer?.histories) return
        let list = compareFilterHistoryReducer.histories
        
        for (let i = 0; i < compareFilterHistoryReducer.histories.length; i++) {
            let item = compareFilterHistoryReducer.histories[i];
            let index = compareFilterHistoryReducer.histories.length - i
            item.index = tools.formatStringID(index, 6);
        }
        if(list) setListData(list)
        return () => {
            
        }
    }, [JSON.stringify(compareFilterHistoryReducer)])

    const callbackSuccess = () => {
        
    }

    const callbackFinal = () => {
        const callbackResetFilterSuccess = () => {
            dispatch(compareFilterHistory({ filterFrom: '', filterSkip: 0, filterSize: -1 }))
        }
        dispatch(resetCompareFilterHistory(callbackResetFilterSuccess))
        setFacePictures({
            pic1: null,
            pic2: null
        })
    }

    const _handleSubmitForm = async () => {
        const base64A = await fileToBase64(facePictures.pic1)
        const base64B = await fileToBase64(facePictures.pic2)
        const dataRequest = {
            photoA: base64A.split(",")[1],
            photoB: base64B.split(",")[1]
        }
        dispatch(getCompareUser(dataRequest, callbackSuccess, null, callbackFinal))
    }

    useEffect(() => {
        setIsDisabledBtnSearch(!facePictures.pic1 || !facePictures.pic2)
    }, [facePictures]);

    const tableContent = useMemo(() => (
        listData.length === 0 ?
        <div className="wrap-empty">
            <EmptyDataControl />
        </div>
        :
        <div className="wrap-list-trans" ref={listRef}>
            <div className="list-trans">
                {
                    listData.map((item, i) => (
                        <HistoryItem key={i} className='history-item'
                            item={item} index={listData.length - i} />
                    ))
                }
            </div>
        </div>
    ), [JSON.stringify(listData)])

    return (
        <WrapFaceComparison>
            <PageHeader>Matching Information</PageHeader>
            <FormSubmitControl extendStyles={extendStylesFormFaceCompare}>
                <div className="wrap-row row-tile">
                    <p className="form-title">Face Picture 1</p>
                    <span></span>
                    <p className="form-title">Face Picture 2</p>

                </div>
                <div className="wrap-row row-data">
                    <DragAndDropImage 
                        file={facePictures.pic1}
                        handleRemoveFile={() => setFacePictures({ pic1: null })}
                        handleDropFiles={files => setFacePictures({ pic1: files[0] })}
                    />
                    <div className="icon">
                        <IcFindOne />
                    </div>
                    <DragAndDropImage 
                        file={facePictures.pic2}
                        handleRemoveFile={() => setFacePictures({ pic2: null })}
                        handleDropFiles={files => setFacePictures({ pic2: files[0] })}
                    />
                    <Button 
                        text="Match"
                        iconBtn={<IcBtnSearch />}
                        className="btn-submit"
                        isDisable={isDisabledBtnSearch}
                        onClick={_handleSubmitForm}
                    />
                </div>
            </FormSubmitControl>
            <PageHeader>Search History</PageHeader>
            <TableHeaderFaceComparison>
                {
                    dataHeader.map((item, i) => {
                        let style = {}
                        switch (i) {
                            case 1:
                                style = {paddingLeft: '12px'}
                                break;
                            case 2:
                                // style = {paddingLeft: '14px'}
                                break;
                        
                            default:
                                break;
                        }
                        return (
                            <div className="table-header" key={i} style={style}>
                                {item}
                            </div>
                        )
                    })
                }
            </TableHeaderFaceComparison>
            <TableContentFaceComparison>
                {tableContent}
            </TableContentFaceComparison>
        </WrapFaceComparison>
    )
}

export default FaceComparison
