import React from 'react'

const IcNoData = (props) => {
    return (
        <svg {...props} width={180} height={180} viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M40.3859 155.474H18.6687C16.9548 155.474 15.5664 154.086 15.5664 152.372C15.5664 150.658 16.9548 149.27 18.6687 149.27H40.3859C45.5261 149.27 49.6941 145.102 49.6941 139.962V93.4236C49.6941 91.7112 51.0825 90.3214 52.7964 90.3214C54.5102 90.3214 55.8986 91.7112 55.8986 93.4236V139.962C55.889 148.526 48.9498 155.465 40.3859 155.474Z" fill="#E6E7E8" />
                <path d="M90.0263 167.885C88.3125 167.885 86.9241 166.495 86.9241 164.783V93.4236C86.9241 91.7112 88.3125 90.3214 90.0263 90.3214C91.7402 90.3214 93.1286 91.7112 93.1286 93.4236V164.783C93.1286 166.495 91.7402 167.885 90.0263 167.885Z" fill="#E6E7E8" />
                <path d="M161.385 155.474H139.667C131.104 155.465 124.165 148.526 124.154 139.962V93.4236C124.154 91.7112 125.544 90.3214 127.257 90.3214C128.97 90.3214 130.36 91.7112 130.36 93.4236V139.962C130.36 145.102 134.526 149.27 139.667 149.27H161.385C163.098 149.27 164.487 150.658 164.487 152.372C164.487 154.086 163.098 155.474 161.385 155.474Z" fill="#E6E7E8" />
                <path d="M180 68.6045C179.991 57.2391 172.277 47.3267 161.26 44.5293C160.046 31.7961 149.354 22.0732 136.564 22.0663C131.781 22.054 127.1 23.4465 123.099 26.0681C118.449 7.80333 99.8728 -3.23519 81.6066 1.41478C69.4942 4.49782 60.0377 13.9557 56.9533 26.0681C52.9529 23.4451 48.2713 22.054 43.4881 22.0663C30.6987 22.0718 20.0076 31.7961 18.7922 44.5279C5.49601 47.8609 -2.5817 61.3411 0.74991 74.6373C3.52259 85.6978 13.4721 93.4459 24.8732 93.424H155.179C168.888 93.424 180 82.3113 180 68.6045Z" fill="#FFF4F7" />
                <path d="M90.0263 180.295C95.1665 180.295 99.3344 176.127 99.3344 170.987C99.3344 165.847 95.1665 161.68 90.0263 161.68C84.886 161.68 80.7195 165.847 80.7195 170.987C80.7195 176.127 84.886 180.295 90.0263 180.295Z" fill="#FFF4F7" />
                <path d="M170.692 161.68C175.832 161.68 180 157.512 180 152.372C180 147.232 175.832 143.065 170.692 143.065C165.552 143.065 161.385 147.232 161.385 152.372C161.385 157.512 165.552 161.68 170.692 161.68Z" fill="#FFF4F7" />
                <path d="M9.36051 143.064C4.22028 143.064 0.0537109 147.232 0.0537109 152.372C0.0537109 157.512 4.22028 161.679 9.36051 161.679C14.5007 161.679 18.6687 157.512 18.6687 152.372C18.6687 147.232 14.5007 143.064 9.36051 143.064Z" fill="#FFF4F7" />
                <path d="M86.9241 81.0146H93.13V87.2191H86.9241V81.0146Z" fill="url(#paint0_linear)" />
                <path d="M99.3345 81.0146H105.539V87.2191H99.3345V81.0146Z" fill="url(#paint1_linear)" />
                <path d="M111.745 81.0146H117.949V87.2191H111.745V81.0146Z" fill="url(#paint2_linear)" />
                <path d="M124.154 81.0146H130.36V87.2191H124.154V81.0146Z" fill="url(#paint3_linear)" />
                <path d="M136.564 81.0146H142.77V87.2191H136.564V81.0146Z" fill="url(#paint4_linear)" />
                <path d="M148.975 81.0146H155.179V87.2191H148.975V81.0146Z" fill="url(#paint5_linear)" />
                <path d="M74.5137 81.0146H80.7196V87.2191H74.5137V81.0146Z" fill="url(#paint6_linear)" />
                <path d="M62.103 81.0146H68.3089V87.2191H62.103V81.0146Z" fill="url(#paint7_linear)" />
                <path d="M49.6941 81.0146H55.8986V87.2191H49.6941V81.0146Z" fill="url(#paint8_linear)" />
                <path d="M37.2837 81.0146H43.4882V87.2191H37.2837V81.0146Z" fill="url(#paint9_linear)" />
                <path d="M24.8733 81.0146H31.0792V87.2191H24.8733V81.0146Z" fill="url(#paint10_linear)" />
                <path d="M86.9241 167.885H93.13V174.089H86.9241V167.885Z" fill="url(#paint11_linear)" />
                <path d="M167.59 149.27H173.796V155.474H167.59V149.27Z" fill="url(#paint12_linear)" />
                <path d="M96.2322 99.6295H102.437V105.834H96.2322V99.6295Z" fill="url(#paint13_linear)" />
                <path d="M96.2322 112.039H102.437V118.245H96.2322V112.039Z" fill="url(#paint14_linear)" />
                <path d="M96.2322 124.449H102.437V130.655H96.2322V124.449Z" fill="url(#paint15_linear)" />
                <path d="M96.2322 136.859H102.437V143.065H96.2322V136.859Z" fill="url(#paint16_linear)" />
                <path d="M133.462 99.6295H139.667V105.834H133.462V99.6295Z" fill="url(#paint17_linear)" />
                <path d="M133.462 112.039H139.667V118.245H133.462V112.039Z" fill="url(#paint18_linear)" />
                <path d="M133.462 124.449H139.667V130.655H133.462V124.449Z" fill="url(#paint19_linear)" />
                <path d="M133.462 136.859H139.667V143.065H133.462V136.859Z" fill="url(#paint20_linear)" />
                <path d="M6.25806 149.27H12.464V155.474H6.25806V149.27Z" fill="url(#paint21_linear)" />
                <path d="M40.3857 99.6295H46.5917V105.834H40.3857V99.6295Z" fill="url(#paint22_linear)" />
                <path d="M40.3857 112.039H46.5917V118.245H40.3857V112.039Z" fill="url(#paint23_linear)" />
                <path d="M40.3857 124.449H46.5917V130.655H40.3857V124.449Z" fill="url(#paint24_linear)" />
                <path d="M40.3857 136.859H46.5917V143.065H40.3857V136.859Z" fill="url(#paint25_linear)" />
                <path d="M96.2322 149.27H102.437V155.474H96.2322V149.27Z" fill="url(#paint26_linear)" />
            </g>
            <defs>
                <linearGradient id="paint0_linear" x1="87.7285" y1="82.5041" x2="94.9874" y2="82.8887" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD1B40" />
                    <stop offset={1} stopColor="#951133" />
                </linearGradient>
                <linearGradient id="paint1_linear" x1="100.139" y1="82.5041" x2="107.396" y2="82.8886" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD1B40" />
                    <stop offset={1} stopColor="#951133" />
                </linearGradient>
                <linearGradient id="paint2_linear" x1="112.549" y1="82.5041" x2="119.806" y2="82.8886" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD1B40" />
                    <stop offset={1} stopColor="#951133" />
                </linearGradient>
                <linearGradient id="paint3_linear" x1="124.958" y1="82.5041" x2="132.217" y2="82.8887" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD1B40" />
                    <stop offset={1} stopColor="#951133" />
                </linearGradient>
                <linearGradient id="paint4_linear" x1="137.369" y1="82.5041" x2="144.628" y2="82.8887" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD1B40" />
                    <stop offset={1} stopColor="#951133" />
                </linearGradient>
                <linearGradient id="paint5_linear" x1="149.779" y1="82.5041" x2="157.036" y2="82.8886" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD1B40" />
                    <stop offset={1} stopColor="#951133" />
                </linearGradient>
                <linearGradient id="paint6_linear" x1="75.3181" y1="82.5041" x2="82.577" y2="82.8887" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD1B40" />
                    <stop offset={1} stopColor="#951133" />
                </linearGradient>
                <linearGradient id="paint7_linear" x1="62.9075" y1="82.5041" x2="70.1663" y2="82.8887" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD1B40" />
                    <stop offset={1} stopColor="#951133" />
                </linearGradient>
                <linearGradient id="paint8_linear" x1="50.4984" y1="82.5041" x2="57.7556" y2="82.8886" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD1B40" />
                    <stop offset={1} stopColor="#951133" />
                </linearGradient>
                <linearGradient id="paint9_linear" x1="38.088" y1="82.5041" x2="45.3452" y2="82.8886" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD1B40" />
                    <stop offset={1} stopColor="#951133" />
                </linearGradient>
                <linearGradient id="paint10_linear" x1="25.6778" y1="82.5041" x2="32.9366" y2="82.8887" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD1B40" />
                    <stop offset={1} stopColor="#951133" />
                </linearGradient>
                <linearGradient id="paint11_linear" x1="87.7285" y1="169.374" x2="94.9874" y2="169.759" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD1B40" />
                    <stop offset={1} stopColor="#951133" />
                </linearGradient>
                <linearGradient id="paint12_linear" x1="168.394" y1="150.759" x2="175.653" y2="151.144" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD1B40" />
                    <stop offset={1} stopColor="#951133" />
                </linearGradient>
                <linearGradient id="paint13_linear" x1="97.0365" y1="101.119" x2="104.294" y2="101.504" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD1B40" />
                    <stop offset={1} stopColor="#951133" />
                </linearGradient>
                <linearGradient id="paint14_linear" x1="97.0365" y1="113.528" x2="104.294" y2="113.913" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD1B40" />
                    <stop offset={1} stopColor="#951133" />
                </linearGradient>
                <linearGradient id="paint15_linear" x1="97.0365" y1="125.939" x2="104.294" y2="126.323" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD1B40" />
                    <stop offset={1} stopColor="#951133" />
                </linearGradient>
                <linearGradient id="paint16_linear" x1="97.0365" y1="138.349" x2="104.294" y2="138.734" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD1B40" />
                    <stop offset={1} stopColor="#951133" />
                </linearGradient>
                <linearGradient id="paint17_linear" x1="134.266" y1="101.119" x2="141.524" y2="101.504" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD1B40" />
                    <stop offset={1} stopColor="#951133" />
                </linearGradient>
                <linearGradient id="paint18_linear" x1="134.266" y1="113.528" x2="141.524" y2="113.913" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD1B40" />
                    <stop offset={1} stopColor="#951133" />
                </linearGradient>
                <linearGradient id="paint19_linear" x1="134.266" y1="125.939" x2="141.524" y2="126.323" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD1B40" />
                    <stop offset={1} stopColor="#951133" />
                </linearGradient>
                <linearGradient id="paint20_linear" x1="134.266" y1="138.349" x2="141.524" y2="138.734" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD1B40" />
                    <stop offset={1} stopColor="#951133" />
                </linearGradient>
                <linearGradient id="paint21_linear" x1="7.06253" y1="150.759" x2="14.3214" y2="151.144" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD1B40" />
                    <stop offset={1} stopColor="#951133" />
                </linearGradient>
                <linearGradient id="paint22_linear" x1="41.1902" y1="101.119" x2="48.449" y2="101.504" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD1B40" />
                    <stop offset={1} stopColor="#951133" />
                </linearGradient>
                <linearGradient id="paint23_linear" x1="41.1902" y1="113.528" x2="48.4491" y2="113.913" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD1B40" />
                    <stop offset={1} stopColor="#951133" />
                </linearGradient>
                <linearGradient id="paint24_linear" x1="41.1902" y1="125.939" x2="48.4491" y2="126.323" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD1B40" />
                    <stop offset={1} stopColor="#951133" />
                </linearGradient>
                <linearGradient id="paint25_linear" x1="41.1902" y1="138.349" x2="48.4491" y2="138.734" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD1B40" />
                    <stop offset={1} stopColor="#951133" />
                </linearGradient>
                <linearGradient id="paint26_linear" x1="97.0365" y1="150.759" x2="104.294" y2="151.144" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AD1B40" />
                    <stop offset={1} stopColor="#951133" />
                </linearGradient>
                <clipPath id="clip0">
                    <rect width={180} height={180} fill="white" />
                </clipPath>
            </defs>
        </svg>


    )
}

export default IcNoData
