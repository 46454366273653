import React, { useState, useRef, useEffect, useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux'

// styles
import { WrapMatching1N, extendStylesMatchingManyForm, TableHeaderMatching1N, TableContentMatching1N } from './index.styles'
// component
import FormSubmitControl from '../Control/FormSubmitControl'
import PageHeader from '../Control/PageHeader'
import EmptyDataControl from '../Control/EmptyDataControl'
import HistoryItem from './HistoryItem'
import Button from '../../../../Control/Button'
import DragAndDropImage from '../../../../Control/DragAndDropImage'

//images
import IcBtnSearch from '../../../../../assets/images/ICBtnSearch'

// helper
import fileToBase64 from '../../../../../helper/fileToBase64'
import * as tools from '../../../../../helper/tools'

// action
import { identifyUser } from '../../../../../redux/identifyUser/action'
import { identifyFilterHistory, resetIdentifyFilterHistory } from '../../../../../redux/identifyUser/identifyFilterHistory/action'

const dataHeader = [
    // "#",
    "Face searched",
    "Faceprint",
    "Customer Number",
    "Enroll Date",
    "Status",
    "Confidence Score",
    "Processing Time",
    "Requested Date",
    "Time"
]



const Matching1N = () => {
    const dispatch = useDispatch()
    const listRef = useRef(null)

    const [listData, setListData] = useState([])
    const [isDisabledBtnSearch, setIsDisabledBtnSearch] = useState(true);
    const [faceImg, setFaceImg] = useState(null);

    const { identifyFilterHistoryReducer } = useSelector(state => ({
        identifyFilterHistoryReducer: state.identifyFilterHistoryReducer
    }))

    useEffect(() => {
        dispatch(identifyFilterHistory({ filterFrom: '', filterSkip: 0, filterSize: -1 }))
        return () => {
            
        }
    }, [])

    useEffect(() => {
        setIsDisabledBtnSearch(!faceImg)
    }, [faceImg]);

    useEffect(() => {
        if(!identifyFilterHistoryReducer?.histories) return
        let list = identifyFilterHistoryReducer.histories
        
        for (let i = 0; i < identifyFilterHistoryReducer.histories.length; i++) {
            let item = identifyFilterHistoryReducer.histories[i];
            let index = identifyFilterHistoryReducer.histories.length - i
            item.index = tools.formatStringID(index, 6);
        }
        if(list) setListData(list)
        return () => {
            
        }
    }, [JSON.stringify(identifyFilterHistoryReducer)])

    const callbackSuccess = () => {
        
    }

    const callbackFinal = () => {
        const callbackResetFilterSuccess = () => {
            dispatch(identifyFilterHistory({ filterFrom: '', filterSkip: 0, filterSize: -1 }))
        }
        dispatch(resetIdentifyFilterHistory(callbackResetFilterSuccess))
        
        setFaceImg(null)
    }

    const _handleSubmitForm = async () => {
        const base64 = await fileToBase64(faceImg)
        const dataRequest = { photo: base64.split(",")[1] }
        dispatch(identifyUser(dataRequest, callbackSuccess, null, callbackFinal))
    }

    const tableContent = useMemo(() => (
        listData.length === 0 ?
        <div className="wrap-empty">
            <EmptyDataControl />
        </div>
        :
        <div className="wrap-list-trans" ref={listRef}>
            <div className="list-trans">
                {
                    listData.map((item, i) => (
                        <HistoryItem key={i} className='history-item'
                            item={item} />
                    ))
                }
            </div>
        </div>
    ), [JSON.stringify(listData)])

    return (
        <WrapMatching1N>
            <PageHeader>Matching Information</PageHeader>
            <FormSubmitControl extendStyles={extendStylesMatchingManyForm}>
                <div className="wrap-content">
                    <p className="form-title">Face Picture</p>
                    <div className="form-content">
                        <div className="upload">
                            <DragAndDropImage 
                                file={faceImg}
                                handleRemoveFile={() => setFaceImg(null)}
                                handleDropFiles={files => setFaceImg(files[0])}
                            />
                        </div>
                        <Button 
                            text="Search"
                            isDisable={isDisabledBtnSearch}
                            iconBtn={<IcBtnSearch />}
                            className="btn-submit"
                            onClick={_handleSubmitForm}
                        />
                    </div>
                </div>
            </FormSubmitControl>
            <PageHeader>Search History</PageHeader>
            <TableHeaderMatching1N>
                {
                    dataHeader.map((item, i) => {
                        let style = {}
                        switch (i) {
                            case 2:
                                // style = {paddingLeft: '14px'}
                                break;
                        
                            default:
                                break;
                        }
                        return (
                            <div className="table-header" key={i} style={style}>
                                {item}
                            </div>
                        )
                    })
                }
            </TableHeaderMatching1N>
            <TableContentMatching1N>
                {tableContent}
            </TableContentMatching1N>
        </WrapMatching1N>
    )
}

export default Matching1N
