import styled, { keyframes } from 'styled-components'

const TIME_VISIBILITY = 15000
const TIME_ANIMATION = 1000
const TIME_DELAY_CLOSE = TIME_VISIBILITY + TIME_ANIMATION

const moveDown = keyframes`
    from {
        transform: translate(0, -100%);
    }

    to {
        transform: translate(0, 80px);
    }
`

const moveUp = keyframes`
    from {
        transform: translate(0, 80px);
    }

    to {
        transform: translate(0, -100%);
    }
`

const WrapToast1107 = styled.div`
    min-width: 440px;
    max-height: calc(100vh - 80px);
    width: 440px;
    margin: 0 auto;
    border: 2px solid #F45C5C;
    background: #FFEEEE;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 17px 0 14px;
    position: relative;
    text-align: center;
    animation: ${props => props.isReverse ? moveUp : moveDown} ${TIME_ANIMATION}ms ease-in-out forwards;

    .icon-close {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 18px;
        height: 18px;

        &:hover {
            cursor: pointer;
        }
    }

    .error-title {
        display: flex;
        align-items: center;
        justify-content: center;
        
        span {
            margin-left: 4px;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.01em;
            color: #313131;
        }
    }

    
    .icao-infos {
        overflow: overlay;
        transition: max-height 0.25s ease-in-out;
        &.show-icao {
            max-height: ${props => props.maxHeightInfosEle}px;
        }
        &.hide-icao {
            max-height: 0;
        }

        .ic-icao {
            margin-top: 26px;
        }

        .title-icao-standards {
            margin-top: 18px;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #313131;
        }
    
        .img-icao-standards {
            margin: 14px auto 5px;
            display: block;
        }
    }

    .click-to-expand {
        margin-top: 18px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.expand {
            .arrow {
                transform: rotate(180deg);
            }
        }

        span {
            margin-right: 4px;
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            letter-spacing: -0.02em;
            line-height: 15px;
            color: #313131;
        }

        .arrow {
            transition: transform 0.25s ease-in-out;
        }

        &:hover {
            cursor: pointer;
        }
    }
`;

export {
    TIME_VISIBILITY,
    TIME_DELAY_CLOSE,
    WrapToast1107
}