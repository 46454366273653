import Axios from "axios";
//host
import { host } from "../../constant/host"
//help
import checkIsSuccessAction from "../../helper/checkIsSuccessAction";
import mapErrors from "../../helper/mapErrors";
//type
import * as types from './types'

// action
import { disableLoading, enableLoading } from "../loading/action";

const apiVerifyUser = `${host}/facepay/verifyUser`

let isCalling = false
export const verifyUser = ({ cif, photo }, callbackSuccess, callbackError, callbackFinal) => async dispatch => {
    if(isCalling) return;
    isCalling = true
    dispatch(enableLoading())
    const dataRequest = JSON.stringify({
        cif,
        photo
    })

    try {
        const res = await Axios.post(apiVerifyUser, dataRequest)
        const { data } = res
        callbackSuccess && callbackSuccess()
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.VERIFY_USER,
                payload: data
            })
        }
        else {
            dispatch({
                type: types.VERIFY_USER_ERR,
                payload: data
            })
            callbackError && callbackError()
        }

    } catch (error) {
        dispatch(mapErrors(error, types.VERIFY_USER_ERR))
    }
    dispatch(disableLoading())
    callbackFinal && callbackFinal()
    isCalling = false
}

export const resetVerifyResult = () => async dispatch => {
    dispatch({
        type: types.RESET_VERIFY_USER
    })
}