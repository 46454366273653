import styled from 'styled-components'

const WrapBreadcrumb = styled.div`
    display: flex;
    align-items: center;

    p {
        font-family: "Open Sans";
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #B7B7B7;
    }

    & > p:first-child {
        
    }

    & > p:last-child {
        color: #313131;
    }

    .icon-text {
        padding: 10px;
        transform: rotate(-90deg);
    }
`;

export {
    WrapBreadcrumb
}