import styled, { css } from 'styled-components'

// styles
import { WrapPageItem, TableHeader, TableContent } from '../Enroll/index.styles';

const WrapMatching1N = styled(WrapPageItem)`
    
`;

const extendStylesMatchingManyForm = css`
    padding: 20px;
    box-sizing: border-box;
    .wrap-content{
        .form-title{
            color: #313131;
            font-family: "Open Sans";
            font-weight: 600;
            font-size: 11px;
            line-height: 15px;
            letter-spacing: -0.02em;
            margin-bottom: 17px;
        }
        .form-content{
            display: flex;
            height: 130px;
            align-items: flex-end;
            .upload{
                width: 130px;
                margin-right: 40px;
            }
            .btn-submit{
                width: 180px;
            }
        }
    }
`

const TableHeaderMatching1N = styled(TableHeader)`
    grid-template-columns: 
        /* 60px */
        85px 
        minmax( 90px, 105px)
        minmax( 90px, 115px) 
        minmax( 90px, 100px) 
        minmax( 70px, 100px) 
        minmax( 110px, 1fr) 
        minmax( 110px, 130px) 
        minmax( 110px, 130px) 
        minmax( 60px, 80px);
`;

const TableContentMatching1N = styled(TableContent)`

`;

export {
    WrapMatching1N,
    TableHeaderMatching1N,
    TableContentMatching1N,
    extendStylesMatchingManyForm,
}