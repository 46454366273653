import styled, { css } from 'styled-components'

// styles
import { WrapPageItem, TableHeader, TableContent } from '../Enroll/index.styles';

const WrapFaceComparison = styled(WrapPageItem)`
    
`;

const extendStylesFormFaceCompare = css`
    padding: 20px 20px 24px;
    box-sizing: border-box;
    
    .wrap-row {
        display: grid;
        grid-template-columns: 130px 102px 130px 1fr;

        &.row-title {
            p:nth-child(2) {
                grid-column: 3;
            }
        }

        &.row-data {
            align-items: center;
            .icon{
                display: flex;
                justify-content: center;
            }
        }
    }

    .form-title {
        color: #313131;
        font-family: "Open Sans";
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        letter-spacing: -0.02em;
        margin-bottom: 17px;
    }

    .btn-submit {
        margin-left: 80px;
        width: 181px;
        align-self: flex-end;
    }
`

const TableHeaderFaceComparison = styled(TableHeader)`
    grid-template-columns: 
        /* 60px */
        85px 
        minmax( 120px, 145px)
        minmax( 80px, 170px) 
        minmax( 100px, 1fr) 
        minmax( 110px, 130px) 
        minmax( 110px, 130px) 
        minmax( 60px, 80px);
`;

const TableContentFaceComparison = styled(TableContent)`

`;

export {
    WrapFaceComparison,
    extendStylesFormFaceCompare,
    TableHeaderFaceComparison,
    TableContentFaceComparison
}