import React, { useEffect, useState, useRef } from 'react'

// component
import IcCloseGray from '../../../../assets/images/IcCloseGray'
import IcCloseOutlineRed from '../../../../assets/images/IcCloseOutlineRed'
import IcArrowDownBlack from '../../../../assets/images/IcArrowDownBlack'
import IcICAO from '../../../../assets/images/IcICAO'

// image
import icao_standard from '../../../../assets/images/icao_standard.png'

// styles
import { TIME_VISIBILITY, WrapToast1107 } from './index.styles'

// helper
import timer from '../../../../helper/timer'
import { useOnClickOutside } from '../../../../hook/useClickOutside'

const Toast1107 = ({ onClose }) => {
    const toastRef = useRef(null)
    const timeCount = useRef(null)
    const icaoInfosRef = useRef(null)
    const isHandledAnimateEnd = useRef(false)
    const [isReverse, setIsReverse] = useState(false);  
    const [isExpand, setIsExpand] = useState(false);
    const [maxHeightInfosEle, setMaxHeightInfosEle] = useState(1000);

    useEffect(() => {
        return () => {
            timeCount.current?.stop()
        }
    }, []);

    useEffect(() => {
        const _handleAnimationEnd = (e) => {
            // move up ended
            if(isHandledAnimateEnd.current){
                onClose()
                return;
            }

            isHandledAnimateEnd.current = true
            const { top } = icaoInfosRef.current.getBoundingClientRect()
            const maxHeight = window.innerHeight - top - 47
            setMaxHeightInfosEle(maxHeight)
            // move down ended
            timeCount.current = new timer(() => {
                setIsReverse(true)
            }, TIME_VISIBILITY)
        }
        toastRef.current.addEventListener('animationend', _handleAnimationEnd)
        return () => {
            toastRef.current?.removeEventListener('animationend', _handleAnimationEnd)
        }
    }, []);

    useEffect(() => {
        const _handleMouseEnter = () => {
            timeCount.current?.pause()
        }
        toastRef.current.addEventListener('mouseenter', _handleMouseEnter)
        return () => {
            toastRef.current?.removeEventListener('mouseenter', _handleMouseEnter)
        }
    }, []);

    useEffect(() => {
        const _handleMouseLeave = () => {
            timeCount.current?.resume()
        }
        toastRef.current.addEventListener('mouseleave', _handleMouseLeave)
        return () => {
            toastRef.current?.removeEventListener('mouseleave', _handleMouseLeave)
        }
    }, []);

    useOnClickOutside(toastRef, () => setIsReverse(true))

    return (
        <WrapToast1107 ref={toastRef} isReverse={isReverse} maxHeightInfosEle={maxHeightInfosEle}>
            <IcCloseGray 
                className="icon-close"
                onClick={() => setIsReverse(true)}
            />
            <div className="error-title">
                <IcCloseOutlineRed />
                <span>Error - Picture is not ISO/ICAO Compliant</span>
            </div>
            <div className={`icao-infos${isExpand ? ' show-icao' : ' hide-icao'}`} ref={icaoInfosRef}>
                <IcICAO className="ic-icao" />
                <div className="title-icao-standards">ICAO standards for official photo</div>
                <img className="img-icao-standards" src={icao_standard} alt="ICAO standards" />
            </div>
            <div 
                className={`click-to-expand${isExpand ? ' expand' : ''}`}
                onClick={() => setIsExpand(pre => !pre)}
            >
                <span>
                    {
                        isExpand 
                        ? "Click here to close expand detail"
                        : "Click here to view ICAO standards photo"
                    }
                </span>
                <IcArrowDownBlack className="arrow" />
            </div>
        </WrapToast1107>
    )
}

export default Toast1107
