import React from 'react'

// styles
import { WrapFormSubmitControl } from './index.styles'

const FormSubmitControl = ({ children, extendStyles }) => {
    return (
        <WrapFormSubmitControl extendStyles={extendStyles}>
            {children}
        </WrapFormSubmitControl>
    )
}

export default FormSubmitControl
