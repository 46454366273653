import * as types from './types'

const intitialState = {
    userList: [],
    condFilters: {}
}

const filterUserReducer = (state = intitialState, action) => {
    switch (action.type) {        
        case types.GET_FILTER_USER: {
            const { userList, ...payload } = action.payload
            return {
                ...state,
                ...payload,
                // userList: [...state.userList, ...userList],
                userList
            }
        }
        case types.GET_FILTER_USER_ERR: {
            return intitialState
        }   
        case types.RESET_FILTER_USER: {
            return intitialState
        }     
        default:
            return state;
    }

}

export default filterUserReducer