import React from 'react'
import { NavLink } from "react-router-dom";

//data
import dataMenu from '../../../data/dataMenu';

// styles
import { WrapSideMenu } from './index.styles'

const SideMenu = () => {
    return (
        <WrapSideMenu>
            {
                dataMenu.map((item,index) =>(
                    <NavLink
                        to={item.path}
                        key={index}
                        className="link-item"
                        activeClassName="selected"
                    >
                        <item.IconActive className="icon active"/>
                        <item.Icon className="icon"/>
                        <p>{item.name}</p>
                    </NavLink>
                ))
            }
        </WrapSideMenu>
    )
}

export default SideMenu
