import React from 'react'

const IcCloseOutlineRed = (props) => {
    return (
        <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="#F45C5C"/>
            <path d="M11 5L5 11" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5 5L11 11" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default IcCloseOutlineRed
