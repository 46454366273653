import * as types from './types'

const intitialState = {
    list: [],
}

const getUserReducer = (state = intitialState, action) => {
    switch (action.type) {
        case types.GET_USER: {
            return {
                ...state,
                ...action.payload
              }
        }             
        case types.GET_USER_ERR: {
            return intitialState
        }    
        case types.RESET_GET_USER: {
            return intitialState
        }    
        default:
            return state;
    }

}

export default getUserReducer