import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

// styles
import { WrapApp } from './App.styles'

// component
import Main from './components/Main'
import PrivateRoute from './components/Control/PrivateRoute'
import Login from './components/Login'

// constant
import { MAIN } from './constant/path'

const App = () => {
  return (
    <WrapApp>
      <Switch>
        <Route exact path="/">
          <Login />
        </Route>
        <PrivateRoute path={`/${MAIN}`}>
          <Main />
        </PrivateRoute>
        <Redirect to="/" />
      </Switch>
    </WrapApp>
  )
}

export default App
