import Axios from "axios";
//host
import { host } from "../../../constant/host"
//help
import checkIsSuccessAction from "../../../helper/checkIsSuccessAction";
import mapErrors from "../../../helper/mapErrors";
//type
import * as types from './types'

const apiFilterUser = `${host}/facepay/filterUser`

let isCalling = false
export const getFilterUser = ({ filterFrom, filterSkip, filterSize = -1 }, callbackSuccess) => async dispatch => {
    if(isCalling) return;
    isCalling = true
    const condFilters = {
        filterFrom,
        filterSkip,
        filterSize,
    }

    const dataRequest = JSON.stringify({
        filterFrom,
        filterSkip,
        filterSize,
    })

    try {
        const res = await Axios.post(apiFilterUser, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_FILTER_USER,
                payload: data,
                condFilters
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_FILTER_USER_ERR,
                payload: data
            })
        }

    } catch (error) {
        dispatch(mapErrors(error, types.GET_FILTER_USER_ERR))    
    }
    isCalling = false
}

export const resetFilterUser = (callbackSuccess) => async dispatch => {
    dispatch({
        type: types.RESET_FILTER_USER
    })
    callbackSuccess && callbackSuccess()
}