import styled, { css } from 'styled-components'

const WrapPageItem = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    width: calc(1280px - 100px);
    height: 100%;
    box-sizing: border-box;
`

const TableHeader = styled.div`    
    display: grid;
    grid-gap: 5px; 
    padding: 0 20px;
    box-sizing: border-box;
    width: 100%;
    .table-header {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        color: #313131;
    }

`

const TableContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
    height: 100%;
    .wrap-empty {
        flex-grow: 1;
        display: flex;
        align-items: center;
    }
    .wrap-list-trans {
        width: 100%;
        flex-grow: 1;
        overflow: auto;
        margin-top: 11px;
        position: relative;
        z-index: 0;
        .list-trans {
            width: 100%;
            position: absolute;
            display: flex;
            flex-direction: column;
            .history-item{
                margin-bottom: 8px;
            }
        }
    }
`

const WrapEnroll = styled(WrapPageItem)`

`;

const extendStylesEnrollForm = css`
    padding: 20px;
    box-sizing: border-box;
    .wrap-content{
        display: flex;
        .form-title{
            color: #313131;
            font-family: "Open Sans";
            font-weight: 600;
            font-size: 11px;
            line-height: 15px;
            letter-spacing: -0.02em;
            margin-bottom: 17px;
        }
        .left-content{
            /* width:164px; */
            margin-right: 40px;
        }
        .center-content{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .input-enroll{
                width: 270px;
                margin-bottom: 40px;
            }
            .btn-submit{
                width: 180px;
            }
        }
        .right-content{
            display: flex;
            .btn-generate{
                width: 97px;
                height: fit-content;
                margin: 34px 0 0 12px;
            }
        }
    }
`

const TableHeaderEnroll = styled(TableHeader)`
    grid-template-columns: 
        /* 100px */
        75px 
        minmax( 200px, 1fr)
        minmax( 110px, 130px) 
        minmax( 110px, 130px) 
        minmax( 60px, 80px);
`;

const TableContentEnroll = styled(TableContent)`

`;

export {
    WrapEnroll,
    extendStylesEnrollForm,
    WrapPageItem,
    TableHeader,
    TableHeaderEnroll,
    TableContent,
    TableContentEnroll
}