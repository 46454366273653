import styled from 'styled-components'

const WrapFormSubmitControl = styled.div`
    width: 100%;
    min-height: 206px;
    background: #FFFFFF;
    box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);

    ${props => props.extendStyles}
`;

export {
    WrapFormSubmitControl
}