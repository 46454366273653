import styled, { css } from 'styled-components'

// styles
import { WrapPageItem, TableHeader, TableContent } from '../Enroll/index.styles';

const WrapMatching11 = styled(WrapPageItem)`
    
`;

const extendStylesMatchingOneForm = css`
    padding: 20px;
    box-sizing: border-box;
    .wrap-content{
        display: flex;
        .form-title{
            color: #313131;
            font-family: "Open Sans";
            font-weight: 600;
            font-size: 11px;
            line-height: 15px;
            letter-spacing: -0.02em;
            margin-bottom: 17px;
        }
        .cif-content{
            margin-right: 44px;
            .input-matching{
                width: 270px;
            }
        }
        .facePrint-content{
            margin-right: 42px;
            .facePrint-image{
                width: 130px;
                height: 130px;
                /* border: 2px solid #33ADFF;
                box-sizing: border-box;
                border-radius: 4px; */
                &.default{
                    border: 1px dashed #B7B7B7;
                    box-sizing: border-box;
                    border-radius: 4px;
                    background: #F7F7F7;
                    display: flex;
                    justify-content: center;
                    .icon_faceUser{
                        padding: 17px;
                        border-radius: 50%;
                        background-color: rgba(183, 183, 183, 0.1);
                        margin-top: 16px;
                    }
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 4px;
                }
            }
        }
        .icon-reset{
            margin-right: 42px;
            display: flex;
            align-items: center;
        }
        .facePicture-content{
            .form-content{
                display: flex;
                height: 130px;
                align-items: flex-end;
                .facePicture-image{
                    width: 130px;
                    margin-right: 40px;
                }
                .btn-submit{
                    width: 180px;
                }
            }
        }
    }
`

const TableHeaderMatching11 = styled(TableHeader)`
    grid-template-columns: 
        /* 60px */
        85px 
        minmax( 90px, 105px)
        minmax( 90px, 115px) 
        minmax( 90px, 100px) 
        minmax( 70px, 100px) 
        minmax( 110px, 1fr) 
        minmax( 110px, 130px) 
        minmax( 110px, 130px) 
        minmax( 60px, 80px);
`;

const TableContentMatching11 = styled(TableContent)`

`;

export {
    WrapMatching11,
    TableHeaderMatching11,
    TableContentMatching11,
    extendStylesMatchingOneForm,
}