import Axios from "axios";
//host
import { host } from "../../../constant/host"
//help
import checkIsSuccessAction from "../../../helper/checkIsSuccessAction";
import mapErrors from "../../../helper/mapErrors";
//type
import * as types from './types'

const apiIdentifyFilterHistory = `${host}/facepay/identify/filterHistory`

let isCalling = false
export const identifyFilterHistory = ({ filterFrom, filterSkip, filterSize }, callbackSuccess) => async dispatch => {
    if(isCalling) return;
    isCalling = true

    const condFilters = {
        filterFrom,
        filterSkip,
        filterSize,
    }

    const dataRequest = JSON.stringify({
        filterFrom,
        filterSkip,
        filterSize,
    })

    try {
        const res = await Axios.post(apiIdentifyFilterHistory, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.IDENTIFY_FILTER_HISTORY,
                payload: data,
                condFilters
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.IDENTIFY_FILTER_HISTORY_ERR,
                payload: data
            })
        }

    } catch (error) {
        dispatch(mapErrors(error, types.IDENTIFY_FILTER_HISTORY_ERR))    
    }
    isCalling = false
}

export const resetIdentifyFilterHistory = (callbackSuccess) => async dispatch => {
    dispatch({
        type: types.RESET_IDENTIFY_FILTER_HISTORY
    })
    callbackSuccess && callbackSuccess()
}