import React from 'react'

const IcBtnSearch = ({...props}) => {
    return (
        <svg {...props} width={15} height={14} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.91667 11.0833C9.494 11.0833 11.5833 8.994 11.5833 6.41667C11.5833 3.83934 9.494 1.75 6.91667 1.75C4.33934 1.75 2.25 3.83934 2.25 6.41667C2.25 8.994 4.33934 11.0833 6.91667 11.0833Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.75 12.25L10.2125 9.71252" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcBtnSearch
