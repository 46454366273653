import styled from "styled-components";

const ButtonWrapper = styled.div`
    pointer-events: ${props => props.isDisable ? "none" : "auto"};
    .wrap-button{
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 4px;
        position: relative;
        padding: 8px;
        background: #33ADFF;
        cursor: pointer;
        &.disable{
            background: #B7B7B7;
        }
        button{
            background: transparent;
            border: none;
            outline: none;
            color: white;
            font-family: "Open Sans";
            font-weight: 600;
            font-size: 13px;
            line-height: 18px;
            cursor: pointer;
        }
    }
`

export {ButtonWrapper};