import React, { useRef } from 'react'

// component
import IcCloseWhite from '../../../../../../assets/images/IcCloseWhite'

// hook
import { useOnClickOutside } from '../../../../../../hook/useClickOutside'

// styles
import { OverlayFullScreen, WrapShowZoomFace } from './index.styles'

const ShowZoomFace = ({ lstFaces, onClose }) => {

    const popupRef = useRef(null)

    useOnClickOutside(popupRef, onClose)

    return (
        <OverlayFullScreen>
            <WrapShowZoomFace ref={popupRef}>
                <IcCloseWhite className="close" onClick={onClose} />
                {
                    lstFaces.map(({ imgUrl, imgTitle }) => (
                        <div className="wrap-img" key={imgUrl}>
                            <img src={imgUrl} alt={imgTitle} />
                            <p>{imgTitle}</p>
                        </div>
                    ))
                }
            </WrapShowZoomFace>
        </OverlayFullScreen>
    )
}

export default ShowZoomFace
