import React from 'react'

export default function IcGenerate(props) {
    return (
        <svg {...props} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.1667 5.25H6.91667C6.27233 5.25 5.75 5.77233 5.75 6.41667V11.6667C5.75 12.311 6.27233 12.8333 6.91667 12.8333H12.1667C12.811 12.8333 13.3333 12.311 13.3333 11.6667V6.41667C13.3333 5.77233 12.811 5.25 12.1667 5.25Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.41667 8.75H2.83333C2.52391 8.75 2.22717 8.62708 2.00837 8.40829C1.78958 8.1895 1.66667 7.89275 1.66667 7.58333V2.33333C1.66667 2.02391 1.78958 1.72717 2.00837 1.50837C2.22717 1.28958 2.52391 1.16666 2.83333 1.16666H8.08333C8.39275 1.16666 8.6895 1.28958 8.90829 1.50837C9.12708 1.72717 9.25 2.02391 9.25 2.33333V2.91666" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}
